import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressSpinnerUpdateService {

  public onDescriptionUpdate: BehaviorSubject<string> = new BehaviorSubject("");
  public onPercentageUpdate: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor() { }

  updateDescription(description: string) {
    this.onDescriptionUpdate.next(description);
  }

  updatePercentage(percentage: number) {
    this.onPercentageUpdate.next(percentage);
  }
}
