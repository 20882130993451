import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

// source: https://theinfogrid.com/tech/developers/angular/refreshing-authorization-tokens-angular-6/

@Injectable()
export class JWTRefreshInterceptor implements HttpInterceptor {
  inflightAuthRequest: any;
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => {
      if (error.status === 401) {
        return this.handle401Error(request, next, error);
      }
      return throwError(error);
    }));
  }

  logout() {
    this.authService.logOut();
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler, error: any): Observable<HttpEvent<any>> {
    if (request.url.includes('auth/refresh')) {
      this.logout();
      return throwError(error);
    }
    if (!this.inflightAuthRequest) {
      this.inflightAuthRequest = this.authService.getNewAccessToken();
      if (!this.inflightAuthRequest) {
        this.logout();
        return throwError(error);
      }
    }
    return this.inflightAuthRequest.pipe(
      switchMap((newToken: string) => {
        this.inflightAuthRequest = null;

        const authReqRepeat = request.clone({
          setHeaders: {
            Authorization: `Bearer ${newToken}`
          }
        });

        return next.handle(authReqRepeat);
      })
    );
  }
}

