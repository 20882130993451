import { Injectable } from '@angular/core';
import { Mesh } from '@babylonjs/core';

@Injectable({
  providedIn: 'root'
})
export class TransferSkinweightsService {

  constructor() { }

  transferSkinWeights(sourceMesh: Mesh, targetMesh: Mesh, nearestVerticesIndices: number[]) {
    const sourceWeights = sourceMesh.getVerticesData("matricesWeights");
    const sourceIndices = sourceMesh.getVerticesData("matricesIndices");

    if (!sourceWeights || !sourceIndices) {
      console.error("Source mesh does not have skinning data.");
      return;
    }

    // Prepare new arrays for the target mesh
    let targetWeights = new Float32Array(targetMesh.getTotalVertices() * 4); // Assuming 4 influences per vertex
    let targetIndices = new Float32Array(targetMesh.getTotalVertices() * 4);

    nearestVerticesIndices.forEach((sourceVertexIndex, targetVertexIndex) => {
      for (let i = 0; i < 4; i++) { // Copy each influence
        targetWeights[targetVertexIndex * 4 + i] = sourceWeights[sourceVertexIndex * 4 + i];
        targetIndices[targetVertexIndex * 4 + i] = sourceIndices[sourceVertexIndex * 4 + i];
      }
    });

    targetMesh.setVerticesData("matricesWeights", targetWeights);
    targetMesh.setVerticesData("matricesIndices", targetIndices);
  }


}
