<app-navbar (uploadClick)="onUploadClick()"></app-navbar>
<div class="container mx-auto flex flex-wrap flex-row items-center mb-4 z-10 relative" >
    <a class="text-md bg-grey-200 dark:bg-grey-800 text-black dark:text-white radius-lg pl-4 pr-2 py-1 rounded-r-xl md:rounded-xl flex flex-row gap-2 items-center">
      New look
      <object class="w-4 h-4 invert dark:invert-0" data="assets/img/svg/edit.svg" type="image/svg+xml"></object>
    </a>

  </div>

<app-dialog #uploadDialog title="Upload custom garment">
  <p>Upload a custom garment to modify, please follow these <a href="#">guidelines</a></p>

  <form class="flex flex-col gap-8 pt-4" (ngSubmit)="startUploadCustomGarment()">
    <div class="flex flex-col items-center justify-center border-2 border-dashed border-grey-300 dark:border-grey-600 rounded-md p-4 cursor-pointer hover:border-grey-500 dark:hover:border-grey-300 transition-colors duration-300" (click)="fileInput.click()">
      <input #fileInput type="file" accept=".glb" class="hidden">
      <object class="w-16 h-16 mb-2 pointer-events-none invert dark:invert-0" data="assets/img/svg/upload.svg" type="image/svg+xml"></object>
      <p class="text-sm text-grey-700 dark:text-grey-300 text-center">Drag and drop a GLB file here, or click to select a file</p>
    </div>

    <div>
      <label class="block text-sm font-medium text-grey-700 dark:text-grey-300 uppercase font-bold">Select base</label>
      <div class="mt-2 flex space-x-4 flex justify-center">
        <button type="button" class="w-20 h-20 flex items-center justify-center border-2 border-grey-300 dark:border-grey-600 rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          [ngClass]="{'bg-grey-900 dark:bg-grey-100': selectedBase === 'male', 'bg-transparent': selectedBase !== 'male'}"
          (click)="selectedBase = 'male'">
          <object class="w-16 h-16 pointer-events-none" [ngClass]="{'invert dark:invert-0': selectedBase !== 'male', 'invert-0 dark:invert': selectedBase === 'male'}" data="assets/img/svg/type1_icon.svg" type="image/svg+xml"></object>
        </button>
        <button type="button" class="w-20 h-20 flex items-center justify-center border-2 border-grey-300 dark:border-grey-600 rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          [ngClass]="{'bg-grey-900 dark:bg-grey-100': selectedBase === 'female', 'bg-transparent': selectedBase !== 'female'}"
          (click)="selectedBase = 'female'">
          <object class="w-16 h-16 pointer-events-none" [ngClass]="{'invert dark:invert-0': selectedBase !== 'female', 'invert-0 dark:invert': selectedBase === 'female'}" data="assets/img/svg/type2_icon.svg" type="image/svg+xml"></object>
        </button>
      </div>
    </div>

    <button type="submit" class="w-full py-2 px-4 bg-grey-300 dark:bg-grey-700 rounded-full font-medium shadow-sm hover:bg-grey-400 dark:hover:bg-grey-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-indigo-700"
      [disabled]="!fileInput.files">
      Upload
    </button>
  </form>
</app-dialog>

<app-dialog #customAssetOptimizationIssuesDialog title="Optimization issues">
  <div class="p-4">
    <p class="text-sm text-grey-700 dark:text-grey-300 mb-4">The uploaded garment has some issues that need to be fixed before it can be used in the configurator. Please fix the issues and try again.</p>
    <table class="w-full divide-y divide-grey-200 dark:divide-grey-700">
      <thead class="bg-grey-50 dark:bg-grey-800">
        <tr>
          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grey-500 dark:text-grey-400 uppercase tracking-wider">Status</th>
          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grey-500 dark:text-grey-400 uppercase tracking-wider">Check</th>
          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grey-500 dark:text-grey-400 uppercase tracking-wider">Target</th>
          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grey-500 dark:text-grey-400 uppercase tracking-wider">Current</th>
        </tr>
      </thead>
      <tbody class="bg-white dark:bg-grey-900 divide-y divide-grey-200 dark:divide-grey-700">
        <tr *ngFor="let check of currentOptimizeCheck | keyvalue">
          <td class="px-6 py-4 whitespace-nowrap">
            <span [ngClass]="{'text-green-500': check.value.passed, 'text-red-500': !check.value.passed}">
              <svg *ngIf="check.value.passed" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
              <svg *ngIf="!check.value.passed" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </span>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-grey-700 dark:text-grey-300">{{ check.key }}</td>
          <td class="px-6 py-4 whitespace-nowrap text-grey-500 dark:text-grey-400">{{ check.value.target_value }}</td>
          <td class="px-6 py-4 whitespace-nowrap text-grey-500 dark:text-grey-400">{{ check.value.current_value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</app-dialog>

<app-dialog #exportGarmentDialog title="Export Garment">
  <form class="flex flex-col gap-8 pt-4" (ngSubmit)="exportActiveGarment(customFileName)">
    <input type="text" class="w-full bg-grey-300 dark:bg-grey-700 rounded-full px-4 py-2" placeholder="Name of the Garment" [(ngModel)]="customFileName" name="name">

    <button type="submit" class="w-full py-2 px-4 bg-grey-300 dark:bg-grey-700 rounded-full font-medium shadow-sm hover:bg-grey-400 dark:hover:bg-grey-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-indigo-700" >
      Upload
    </button>
  </form>
</app-dialog>

<app-dialog #exportAvatarDialog title="Export Avatar">
</app-dialog>


<div class="overflow-hidden absolute top-0 left-0 right-0 bottom-0 pointer-events-none" *ngIf="slotsVisible">
  <div *ngFor="let slot of slots" (click)="showGarmentsForSlot(slot)" class="absolute w-28 h-28 z-10 pointer-events-auto cursor-pointer opacity-20 hover:opacity-100 transition-opacity duration-300 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center" [style.top]="slot.top" [style.left]="slot.left">
    <div class="rounded-full bg-grey-900 dark:bg-grey-100 h-6 w-6 flex items-center justify-center">
      <object class="w-full h-full pointer-events-none dark:invert" data="assets/img/svg/keyframe.svg" type="image/svg+xml"></object>
    </div>
  </div>
</div>
<div class="container mx-auto relative flex flex-col items-end z-10 pointer-events-none" >
  <div class="flex flex-col items-center">
  <div *ngIf="!rightPanel.hidden" (click)="resetNavigation()"  class="w-14 h-14 p-3 rounded-full bg-white dark:bg-grey-500 transition-border duration-300 mb-4 pointer-events-auto cursor-pointer" >
    <object class="w-full h-full pointer-events-none" data="assets/img/svg/loop_back.svg" type="image/svg+xml"></object>
  </div>
  <app-configurator-right-panel #rightPanel (activeItemChange)="onRightPanelActiveItemChange($event)"></app-configurator-right-panel>
  </div>
</div>


<app-transform-controls #transformControls [viewport]="viewportRef" (onTransformConfirm)="onTransformConfirm()"></app-transform-controls>
<app-configurator-property-panel #propertyPanel [activeValues]="activeValues" [viewport]="viewportRef"></app-configurator-property-panel>

<app-viewport #viewportRef></app-viewport>

<!-- <div class="ui-overlay"> -->
<!--   <div class="section" *ngIf="setup && selectingBase"> -->
<!--     <h1>Select base model</h1> -->
<!--     <div class="button-group"> -->
<!--       <button (click)="selectMaleBase()">Male</button> -->
<!--       <button (click)="selectFemaleBase()">Female</button> -->
<!--     </div> -->
<!--     <button class="primary" (click)="continueToUpload()">Select base</button> -->
<!--   </div> -->

<!--   <div class="section" *ngIf="setup && uploading"> -->
<!--     <h1>Upload File</h1> -->
<!--     <input type="file" (change)="loadFile($event)"> -->
<!--   </div> -->
<!--   <div class="section" *ngIf="setup && placing"> -->
<!--     <h1>Place Object</h1> -->
<!--     <div class="button-group"> -->
<!--       <button (click)="resetObject()">Reset</button> -->
<!--       <button (click)="enablePositionGizmo()">Move</button> -->
<!--       <button (click)="enableRotateGizmo()">Rotate</button> -->
<!--       <button (click)="enableScaleGizmo()">Scale</button> -->
<!--     </div> -->
<!--     <button class="primary" (click)="placeObject()">Place Object</button> -->
<!--   </div> -->
<!--   <div class="section" *ngIf="setup && selectingCategory"> -->
<!--     <h1>Select Category</h1> -->
<!--     <!-- dropdown -->
<!--     <select [(ngModel)]="selectedCategory"> -->
<!--       <option *ngFor="let category of categories" [ngValue]="category"> -->
<!--         {{category.name}} -->
<!--       </option> -->
<!--     </select> -->
<!--     <label>Name</label> -->
<!--     <input type="text" [(ngModel)]="importName"> -->
<!--     <button class="primary" (click)="generateCustomClothing()">Generate</button> -->
<!--   </div> -->
<!--   <div class="section" *ngIf="generating"> -->
<!--     <app-progress-spinner></app-progress-spinner> -->
<!--   </div> -->
<!--   <div class="section" *ngIf="!setup"> -->
<!--     <app-xy-coord-input (onValueChange)="applyXYCoords($event)"></app-xy-coord-input> -->
<!--     <button (click)="toggleAnimation()">Toggle Animation</button> -->
<!--   </div> -->

<!--   <div class="section" *ngIf="!setup"> -->
<!--     <h1>Clothing</h1> -->
<!--     <ul class="clothing-list"> -->
<!--       <ng-container *ngFor="let clothes of customClothing | keyvalue" > -->
<!--         <li *ngIf="clothes.value.length"> -->
<!--           <!-- <span class="type-name">{{clothes.key}}</span> -->
<!--           <ul> -->
<!--             <li *ngFor="let clothing of clothes.value; let i = index"> -->
<!--               {{clothing.name}} ({{clothes.key}}) -->
<!--               <button (click)="removeClothing(clothes.key, i)">Remove</button> -->
<!--             </li> -->
<!--           </ul> -->
<!--         </li> -->
<!--       </ng-container> -->
<!--     </ul> -->

<!--     <button class="primary" (click)="addClothing()">+</button> -->
<!--   </div> -->
<!--   <div class="section" *ngIf="!setup"> -->
<!--   </div> -->
<!-- </div> -->
