<canvas #renderCanvas></canvas>
<!-- <div class="ui-overlay"> -->
<!--   <!-- Morph Target Sliders --> -->
<!--   <div class="section" *ngIf="setup && uploading"> -->
<!--     <h1>Upload File</h1> -->
<!--     <input type="file" (change)="loadFile($event)"> -->
<!--   </div> -->
<!--   <div class="section" *ngIf="setup && placing"> -->
<!--     <h1>Place Object</h1> -->
<!--     <div class="button-group"> -->
<!--     <button (click)="resetObject()">Reset</button> -->
<!--     <button (click)="enablePositionGizmo()">Move</button> -->
<!--     <button (click)="enableRotateGizmo()">Rotate</button> -->
<!--     <button (click)="enableScaleGizmo()">Scale</button> -->
<!--     </div> -->
<!--     <button (click)="placeObject()">Place Object</button> -->
<!--   </div> -->
<!--   <div class="section" *ngIf="setup && selectingCategory"> -->
<!--     <h1>Select Category</h1> -->
<!--     <!-- dropdown --> -->
<!--     <select [(ngModel)]="selectedCategory"> -->
<!--       <option *ngFor="let category of categories" [value]="category"> -->
<!--         {{category}} -->
<!--       </option> -->
<!--     </select> -->
<!--     <button (click)="generateCustomClothing()">Generate</button> -->
<!--   </div> -->
<!--   <div class="section" *ngIf="!setup"> -->
<!--     <app-xy-coord-input (onValueChange)="applyXYCoords($event)"></app-xy-coord-input> -->
<!--     <button (click)="toggleAnimation()">Toggle Animation</button> -->
<!--   </div> -->
<!-- </div> -->

