import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { SceneComponent } from './scene/scene.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfiguratorPageComponent } from './components/pages/configurator-page/configurator-page.component';
import { XyCoordInputComponent } from './components/ui/inputs/xy-coord-input/xy-coord-input.component';
import { ViewportComponent } from './components/viewport/viewport.component';
import { AppRoutingModule } from './app-routing.module';
import { TransferTestPageComponent } from './components/pages/transfer-test-page/transfer-test-page.component';
import { ProgressSpinnerComponent } from './components/ui/progress-spinner/progress-spinner.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LoggedOutPageComponent } from './components/pages/logged-out-page/logged-out-page.component';
import { NotFoundPageComponent } from './components/pages/not-found-page/not-found-page.component';
import { AccountPageComponent } from './components/pages/account-page/account-page.component';
import { ProfilePageComponent } from './components/pages/profile-page/profile-page.component';
import { LogInPageComponent } from './components/pages/log-in-page/log-in-page.component';
import { NavbarComponent } from './components/ui/navbar/navbar.component';
import { ThemeSwitchComponent } from './components/ui/theme-switch/theme-switch.component';
import { ConfiguratorPropertyPanelComponent } from './components/ui/configurator-property-panel/configurator-property-panel.component';
import { SliderComponent } from './components/ui/inputs/slider/slider.component';
import { ColorPickerComponent } from './components/ui/inputs/color-picker/color-picker.component';
import { DialogComponent } from './components/ui/dialog/dialog.component';
import { TransformControlsComponent } from "./components/ui/transform-controls/transform-controls.component";
import { ConfiguratorRightPanelComponent } from './components/ui/configurator-right-panel/configurator-right-panel.component';
import { JWTAccessInterceptor } from './interceptors/jwt.access.interceptor';
import { JWTRefreshInterceptor } from './interceptors/jwt.refresh.interceptor';


@NgModule({ declarations: [
        AppComponent,
        SceneComponent,
        XyCoordInputComponent,
        SliderComponent,
        ColorPickerComponent,
        ViewportComponent,
        ConfiguratorPageComponent,
        TransferTestPageComponent,
        ProgressSpinnerComponent,
        LoggedOutPageComponent,
        NotFoundPageComponent,
        AccountPageComponent,
        ProfilePageComponent,
        LogInPageComponent,
        NavbarComponent,
        ThemeSwitchComponent,
        ConfiguratorPropertyPanelComponent,
        DialogComponent,
        TransformControlsComponent,
        ConfiguratorRightPanelComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule], providers: [
        provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: JWTAccessInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JWTRefreshInterceptor, multi: true },
    ] })
export class AppModule { }
