import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Mesh } from '@babylonjs/core';

export enum Slot {
  Head,
  Torso,
  Legs,
  Hands,
  Feet,
}

export interface ICollection {
  id: number;
  name: string;
  description: string;
  thumbnail: string;
  created_at?: string;
  updated_at?: string;
  garments?: IGarment[];
}

export interface IGarment {
  id?: number;
  name: string;
  description: string;
  thumbnail: string;
  glb_file: string;
  attributes: IGarmentAttribute[];
  created_at: string;
  updated_at: string;
  slot: Slot;
  mesh?: Mesh;
  equipped: boolean;
}

export interface IGarmentAttribute {
  id: number;
  name: string;
  default_value: string;
}

@Injectable({
  providedIn: 'root',
})
export class GarmentService {
  private baseUrl = environment.api_base_url;

  constructor(private http: HttpClient) {}

  getCollections(): Observable<ICollection[]> {
    return this.http.get<ICollection[]>(`${this.baseUrl}/collections/`);
  }

  getGarmentsBySlot(slot: Slot): Observable<IGarment[]> {
    const params = new HttpParams().set('slot', slot.toString());
    return this.http.get<IGarment[]>(`${this.baseUrl}/garments/`, { params });
  }
}
