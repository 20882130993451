import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';


@Injectable()
export class JWTAccessInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('auth/refresh')) {
      return next.handle(request);
    }

    if (this.authService.isLoggedIn) {
      console.log("adding token");
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.getJWTAccessToken()}`
        }
      });
    }

    return next.handle(request);
  }
}


