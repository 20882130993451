import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ViewportComponent } from '../../viewport/viewport.component';
import { AssetContainer, Color3, Color4, Mesh, StandardMaterial, Vector3 } from '@babylonjs/core';
import { GltfImportService } from 'src/app/services/babylonjs/custom-assets/gltf-import.service';
import { ReferenceModelTransferService } from 'src/app/services/babylonjs/custom-assets/reference-model-transfer.service';

@Component({
  selector: 'app-transfer-test-page',
  templateUrl: './transfer-test-page.component.html',
  styleUrls: ['./transfer-test-page.component.scss']
})
export class TransferTestPageComponent implements AfterViewInit {
  @ViewChild(ViewportComponent) viewport!: ViewportComponent;

  constructor(private gltfImportService: GltfImportService, private transferService: ReferenceModelTransferService) {}

  ngAfterViewInit(): void {
    const scene = this.viewport.scene;
    scene.clearColor = new Color4(0, 0, 0, 1);
    this.loadMeshes().then((assetContainers) => {
      const source_mesh = this.getMeshFromAssetContainer(assetContainers[0], new Color3(0, 1, 0))
      const target_mesh = this.getMeshFromAssetContainer(assetContainers[1], new Color3(1, 0, 0), 1)
      const femaleBase = false

      this.transferService.transfer(source_mesh, source_mesh, target_mesh, femaleBase)

      this.animateMorphTargets(source_mesh, target_mesh)
    })
  }

  animateMorphTargets(source_mesh: Mesh, target_mesh: Mesh): void {
    this.updateMorphTarget(source_mesh, 0, 1)
    this.updateMorphTarget(target_mesh, 0, 1)
    this.updateMorphTarget(source_mesh, 1, 0)
    this.updateMorphTarget(target_mesh, 1, 0)

    let time = 0;
    this.viewport.scene.onBeforeRenderObservable.add(() => {
      time++;
      this.updateMorphTarget(source_mesh, 0, Math.max(0, Math.sin(time * 0.01)))
      this.updateMorphTarget(target_mesh, 0, Math.max(0, Math.sin(time * 0.01)))
    })
  }

  updateMorphTarget(mesh: Mesh, index: number, value: number): void {
    const morphTargetManager = mesh.morphTargetManager!;
    if(!morphTargetManager) {
      console.error(`Mesh ${mesh.name} does not have a morph target manager.`);
      return;
    }
    let morphTarget = morphTargetManager.getTarget(index);
    morphTarget.influence = value;
  }

  getMeshFromAssetContainer(assetContainer: AssetContainer, emissiveColor: Color3,  index = 1): Mesh {
    if(assetContainer.animationGroups.length > 0) {
      assetContainer.animationGroups[0].stop()
    }
    const mesh = assetContainer.meshes[index] as Mesh;
    const wireframeMaterial = new StandardMaterial('wireframe', this.viewport.scene);
    wireframeMaterial.emissiveColor = emissiveColor;
    wireframeMaterial.wireframe = true;
    mesh.material = wireframeMaterial;
    return mesh;
  }

  private loadMeshes(): Promise<[AssetContainer, AssetContainer]> {
   return Promise.all([
      this.gltfImportService.importGltf('assets/geom/', 'transfer_test_source.glb', this.viewport.scene),
      this.gltfImportService.importGltf('assets/geom/', 'transfer_test_target.glb', this.viewport.scene)
    ])
  }


}
