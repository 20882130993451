<div class="absolute left-0 right-0 bottom-0 z-10 pointer-events-none" *ngIf="!hidden">
    <div class="container mx-auto">
        <div class="w-full md:w-48 md:py-4 flex flex-col items-center pointer-events-auto">

            <div class="bg-grey-700 dark:bg-white text-white dark:text-black px-4 py-2 rounded-t-2xl text-xs font-bold uppercase flex gap-2 items-center justify-center">
              <object class="w-4 h-4 pointer-events-none dark:invert" data="assets/img/svg/edit.svg" type="image/svg+xml"></object>
              {{title}}
            </div>
            <div class="w-full bg-grey-200 dark:bg-grey-800 text-black dark:text-white px-3 py-3 rounded-t-2xl md:rounded-2xl ">
                <div class="flex flex-row gap-4 justify-evenly w-full md:hidden">
                    <ng-container *ngIf="activeCategory && activeCategory.alias === 'avatar'">
                        <div (click)="activateTab('avatar_gender')">
                            <object
                                class="w-14 h-14 invert dark:invert-0 transition-opacity duration-300 pointer-events-none"
                                [ngClass]="{'opacity-100': activeTab === 'avatar_gender', 'opacity-50': activeTab !== 'avatar_gender'}"
                                data="assets/img/svg/gender.svg" type="image/svg+xml"></object>
                        </div>
                        <div (click)="activateTab('avatar_roughness')">
                            <object
                                class="w-14 h-14 invert dark:invert-0 transition-opacity duration-300 pointer-events-none"
                                [ngClass]="{'opacity-100': activeTab === 'avatar_roughness', 'opacity-50': activeTab !== 'avatar_roughness'}"
                                data="assets/img/svg/material.svg" type="image/svg+xml"></object>
                        </div>
                        <div (click)="activateTab('avatar_size')">
                            <object
                                class="w-14 h-14 invert dark:invert-0 transition-opacity duration-300 pointer-events-none"
                                [ngClass]="{'opacity-100': activeTab === 'avatar_size', 'opacity-50': activeTab !== 'avatar_size'}"
                                data="assets/img/svg/con_sizelike.svg" type="image/svg+xml"></object>
                        </div>
                        <div (click)="activateTab('avatar_export')">
                            <object
                                class="w-14 h-14 invert dark:invert-0 transition-opacity duration-300 pointer-events-none"
                                [ngClass]="{'opacity-100': activeTab === 'avatar_export', 'opacity-50': activeTab !== 'avatar_export'}"
                                data="assets/img/svg/download.svg" type="image/svg+xml"></object>
                        </div>
                    </ng-container>
                </div>
                <div class="flex flex-row md:flex-col gap-4 justify-center w-full">
                    <ng-container *ngIf="activeCategory && activeCategory.alias === 'avatar'">
                        <div class="w-full h-full flex-col gap-2"
                            [ngClass]="{'flex': activeTab === 'avatar_gender', 'hidden md:flex': activeTab !== 'avatar_gender'}">
                            <h2 class="hidden md:block uppercase font-bold text-xs">Gender</h2>
                            <app-xy-coord-input [values]="activeValues['curvy-gender']"
                                (onValueChange)="applyXYCoords($event)"></app-xy-coord-input>
                        </div>
                        <div class="w-full h-full flex-col gap-2"
                            [ngClass]="{'flex': activeTab === 'avatar_roughness', 'hidden md:flex': activeTab !== 'avatar_roughness'}">
                            <h2 class="hidden md:block uppercase font-bold text-xs">Avatar Material</h2>
                            <app-slider label="Roughness" [min]="0" [max]="1" [steps]="0.01"
                                [value]="activeValues['avatar_roughness']"
                                (onValueChange)="changeRoughness($event)"></app-slider>
                            <app-slider label="Metallic" [min]="0" [max]="1" [steps]="0.01"
                                [value]="activeValues['avatar_metallic']"
                                (onValueChange)="changeMetallic($event)"></app-slider>
                            <app-color-picker label="Color" [color]="activeValues['avatar_color']"
                                (onColorChange)="changeAvatarColor($event)"></app-color-picker>
                        </div>
                        <div class="w-full h-full flex-col gap-2 justify-center"
                            [ngClass]="{'flex': activeTab === 'avatar_size', 'hidden md:flex': activeTab !== 'avatar_size'}">
                            <h2 class="hidden md:block uppercase font-bold text-xs">Avatar Size</h2>
                            <span class="uppercase text-xs text-grey-500 dark:text-grey-400"> To be implemented</span>
                        </div>
                        <div class="w-full h-full flex-col gap-2 justify-center"
                            [ngClass]="{'flex': activeTab === 'avatar_export', 'hidden md:flex': activeTab !== 'avatar_export'}">
                            <h2 class="hidden md:block uppercase font-bold text-xs">Export Avatar</h2>
                            <button (click)="exportGltf()"
                                class="bg-grey-500 dark:bg-grey-700 text-black dark:text-white font-bold py-2 px-8 rounded-full w-full">
                                GLB
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="activeCategory && activeCategory.alias === 'garment'">
                        <div class="w-full h-full pt-4 flex justify-center flex flex-col gap-2"
                            [ngClass]="{'block': activeGarment !== undefined, 'hidden': activeGarment === undefined}">
                            <h2 class="hidden md:block uppercase font-bold mb-2">Export Garment</h2>
                            <button (click)="exportGarmentGltf()"
                                class="bg-grey-500 dark:bg-grey-700 text-black dark:text-white font-bold py-2 px-8 rounded-full w-full">
                                GLB
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
