import { Injectable } from '@angular/core';
import { GizmoManager, Mesh, Scene } from '@babylonjs/core';

@Injectable({
  providedIn: 'root'
})
export class GizmoService {

  private gizmoManager!: GizmoManager;

  constructor() { }

  setupGizmo(scene: Scene) {
    this.gizmoManager = new GizmoManager(scene);
    this.gizmoManager.usePointerToAttachGizmos = false;
  }

  enable(mesh: Mesh) {
    this.gizmoManager.attachToMesh(mesh);
    this.enablePosition();
  }

  disable(): void {
    this.gizmoManager.positionGizmoEnabled = false;
    this.gizmoManager.rotationGizmoEnabled = false;
    this.gizmoManager.scaleGizmoEnabled = false;
  }

  enablePosition(): void {
    this.gizmoManager.positionGizmoEnabled = true;
    this.gizmoManager.rotationGizmoEnabled = false;
    this.gizmoManager.scaleGizmoEnabled = false;
  }

  enableRotate(): void {
    this.gizmoManager.positionGizmoEnabled = false;
    this.gizmoManager.rotationGizmoEnabled = true;
    this.gizmoManager.scaleGizmoEnabled = false;
  }

  enableScale(): void {
    this.gizmoManager.positionGizmoEnabled = false;
    this.gizmoManager.rotationGizmoEnabled = false;
    this.gizmoManager.scaleGizmoEnabled = true;
  }



}
