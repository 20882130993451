import { Injectable } from '@angular/core';
import { AssetContainer, Scene, SceneLoader } from '@babylonjs/core';

import '@babylonjs/loaders/glTF/2.0/glTFLoader'; // to load GLTF
import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_unlit'; // to load GLTF
import '@babylonjs/core/Loading/Plugins/babylonFileLoader'; // to load GLTF

@Injectable({
  providedIn: 'root'
})
export class GltfImportService {

  constructor() { }

  importGltf(path: string, fileName: string, scene: Scene): Promise<AssetContainer> {
    return new Promise((resolve, reject) => {
      SceneLoader.LoadAssetContainer(path, fileName, scene, (assetContainer: AssetContainer) => {
        assetContainer.addAllToScene();
        resolve(assetContainer);
      });
    });
  }

  importGltfFromUrl(url: string, scene: Scene): Promise<AssetContainer> {
    return new Promise((resolve, reject) => {
      const path = url.split('/').slice(0, -1).join('/') + "/";
      const fileName = url.split('/').pop()!;
      this.importGltf(path, fileName, scene).then((assetContainer) => {
        resolve(assetContainer);
      });
    });
  }

  importGltfFromFile (file: File, scene: Scene): Promise<AssetContainer> {
    return new Promise((resolve) => {
      SceneLoader.LoadAssetContainer( "", file, scene, (assetContainer: AssetContainer) => {
        assetContainer.addAllToScene();
        resolve(assetContainer);
        },
          null,
          null
        );
    })
  }
}
