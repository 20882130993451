import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss'
})
export class DialogComponent {
  @Input() title: string = 'Title';
  public isOpen: boolean = false;
  
  close() {
    this.isOpen = false;
  }

  open() {
    this.isOpen = true;
  }


}
