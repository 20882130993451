
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Engine, Scene, ArcRotateCamera, Vector3, HemisphericLight, SceneLoader, Mesh, MorphTargetManager, AssetContainer, Color3, StandardMaterial, Color4, MorphTarget, Vector2, GizmoManager, HDRCubeTexture, PBRMaterial, CubeTexture, FxaaPostProcess, DefaultRenderingPipeline} from '@babylonjs/core';
import '@babylonjs/loaders/glTF';

@Component({
  selector: 'app-scene',
  templateUrl: './scene.component.html',
  styleUrls: ['./scene.component.scss']
})
export class SceneComponent implements AfterViewInit {
  @ViewChild('renderCanvas', { static: true }) renderCanvas!: ElementRef<HTMLCanvasElement>;

  private engine!: Engine;
  private scene!: Scene;
  private importedMesh!: Mesh; // Assuming a single mesh for simplicity
  private importedTargetMesh!: Mesh; // Assuming a single mesh for simplicity
  private importedReferenceTshirtMesh!: Mesh; // Assuming a single mesh for simplicity
  private importedReferencePantsMesh!: Mesh; // Assuming a single mesh for simplicity
  private animationsEnabled: boolean = false;
  public uploading: boolean = true;
  public placing: boolean = false;
  public setup: boolean = true;
  public selectingCategory: boolean = false;
  private gizmoManager!: GizmoManager;
  public categories: string[] = [
    'T-shirt',
    'Pants'
  ]
  public selectedCategory: string = 'T-shirt';

  constructor() { }

  ngAfterViewInit(): void {
    this.initializeScene();
  }

  private loadHDR() {
    return new Promise((success) => {
      const environmentTexture = new CubeTexture('assets/img/env/studio.env', this.scene)
      this.scene.environmentTexture = environmentTexture
      this.scene.environmentIntensity = 1

      success(environmentTexture)
    })
  }

  private initializeScene() {
    console.log("INITI")
    this.engine = new Engine(this.renderCanvas.nativeElement, true);
    this.scene = new Scene(this.engine);
    this.gizmoManager = new GizmoManager(this.scene);
    this.gizmoManager.usePointerToAttachGizmos = false;
    this.loadHDR();

    console.log("initializeScene");
    const camera = new ArcRotateCamera("camera", Math.PI + Math.PI / 3, Math.PI / 2.3, 10, new Vector3(0, 1.25, 0), this.scene);
    camera.pinchPrecision = 200;
    camera.panningSensibility = 1000;
    camera.allowUpsideDown = false;
    camera.lowerRadiusLimit = 0.01;
    camera.minZ = 0.030;
    camera.useNaturalPinchZoom = true;
    camera.panningInertia = 0.5;
    camera.lowerRadiusLimit = 1.5;
    camera.upperRadiusLimit = 2;

    camera.attachControl(true);
    camera.position = new Vector3(0, 1.25, -4);
    this.scene.activeCamera = camera;

    // add fxaa antialiasing
    const pipeline = new DefaultRenderingPipeline(
      "defaultPipeline", // The name of the pipeline
      true, // Do you want the pipeline to use HDR texture?
      this.scene, // The scene instance
      [camera] // The list of cameras to be attached to
  );
    pipeline.samples = 4;
    pipeline.fxaaEnabled = true;
    pipeline.bloomEnabled = true;
    pipeline.bloomThreshold = 0.8;
    pipeline.bloomWeight = 0.3;
    pipeline.bloomKernel = 64;
    pipeline.bloomScale = 0.5;

    //new HemisphericLight("light", new Vector3(1, 1, 0), this.scene);
    Promise.all([
      this.loadAssetContainer(
      'assets/geom/',
      'montegreen_A004.glb',
      this.scene,
      (assetContainer: AssetContainer) => {
        this.importedMesh = assetContainer.meshes[1] as Mesh;
        assetContainer.animationGroups[0].stop()
        var greenMat = new PBRMaterial("greenMat", this.scene);
        greenMat.albedoColor = new Color3(0, 0, 0);
        greenMat.roughness = 0.6;
        greenMat.metallic = 0.5;

        this.importedMesh.material = greenMat;
      }
    ),
    this.loadAssetContainer(
      'assets/geom/',
      'montegreen_TSHIRT_A001.glb',
      this.scene,
      (assetContainer: AssetContainer) => {
        const greenMat = new StandardMaterial("greenMat", this.scene);
        greenMat.alpha = 0;
        this.importedReferenceTshirtMesh.material = greenMat;
      }
    ),
    this.loadAssetContainer(
      'assets/geom/',
      'montegreen_PANTS_A002.glb',
      this.scene,
      (assetContainer: AssetContainer) => {
        this.importedReferencePantsMesh = assetContainer.meshes[1] as Mesh;
        assetContainer.animationGroups[0].stop()
        const greenMat = new StandardMaterial("greenMat", this.scene);
        greenMat.alpha = 0;

        //this.shareSkeletonBetweenMeshes(this.importedMesh, this.importedReferencePantsMesh);

        this.importedReferencePantsMesh.material = greenMat;
      }
    )
    ])
      .then(() => {
        this.applyXYCoords(new Vector2(0, 1))
      })

    this.engine.runRenderLoop(() => {
      this.scene.render();
    });

    window.addEventListener('resize', () => {
      this.engine.resize();
    });
  }

  enablePlacing(): void {
    this.placing = true;
    this.enableGizmos()
  }

  placeObject(): void {
    //this.disableGizmos();
    this.placing = false;
    this.selectCategory();
  }

  resetObject(): void {
    this.importedTargetMesh.position = new Vector3(0, 0, 0);
    this.importedTargetMesh.rotation = new Vector3(0, 0, 0);
    this.importedTargetMesh.scaling = new Vector3(1, 1, 1);
  }

  selectCategory(): void {
    this.selectingCategory = true;
  }

  enableGizmos(): void {
    //this.enablePositionGizmo();
    this.gizmoManager.attachToMesh(this.importedTargetMesh);
  }

  generateCustomClothing(): void {
    let mesh
    if(this.selectedCategory == 'T-shirt') {
      mesh = this.importedReferenceTshirtMesh
    } else {
      mesh = this.importedReferencePantsMesh
    }

    this.importedTargetMesh.bakeCurrentTransformIntoVertices();
    this.updateMorphTarget(this.importedTargetMesh, 0, 0);
    this.updateMorphTarget(this.importedTargetMesh, 1, 0);
    this.setup = false;
    this.applyXYCoords(new Vector2(0, 1));

  }

      loadAssetContainer(path: string, fileName: string, scene: Scene, callback: (assetContainer: AssetContainer) => void): Promise<void> {
    return new Promise((resolve, reject) => {
      SceneLoader.LoadAssetContainer(path, fileName, scene, (assetContainer: AssetContainer) => {
        callback(assetContainer);
        assetContainer.addAllToScene();
        resolve();
      });
    });
  }

  changeGreenMatAlpha(event: Event): void {
    const value = parseFloat((event.target as HTMLInputElement).value);
    this.importedMesh.material!.alpha = value;
  }

  changeRedMatAlpha(event: Event): void {
    const value = parseFloat((event.target as HTMLInputElement).value);
    this.importedTargetMesh.material!.alpha = value;
  }

  applyXYCoords(coords: Vector2) {
    const curvy = 1 - coords.y;
    const gender = coords.x;

    this.updateMorphTarget(this.importedMesh, 0, gender * (1 - curvy));
    this.updateMorphTarget(this.importedMesh, 1, (1 - gender) * curvy);
    this.updateMorphTarget(this.importedMesh, 2, gender * curvy);

    this.updateMorphTarget(this.importedReferenceTshirtMesh, 0, gender * (1 - curvy));
    this.updateMorphTarget(this.importedReferenceTshirtMesh, 1, (1 - gender) * curvy);
    this.updateMorphTarget(this.importedReferenceTshirtMesh, 2, gender * curvy);

    this.updateMorphTarget(this.importedReferencePantsMesh, 0, gender * (1 - curvy));
    this.updateMorphTarget(this.importedReferencePantsMesh, 1, (1 - gender) * curvy);
    this.updateMorphTarget(this.importedReferencePantsMesh, 2, gender * curvy);

    if(this.importedTargetMesh){
      this.updateMorphTarget(this.importedTargetMesh, 0, gender * (1 - curvy));
      this.updateMorphTarget(this.importedTargetMesh, 1, (1 - gender) * curvy);
      this.updateMorphTarget(this.importedTargetMesh, 2, gender * curvy);
    }
  }

  updateMorphTarget(mesh: Mesh, index: number, value: number): void {
    const morphTargetManager = mesh.morphTargetManager!;
    let morphTarget = morphTargetManager.getTarget(index);
    morphTarget.influence = value;
  }

  toggleAnimation() {
    const animationGroup = this.scene.animationGroups[0];

    if (animationGroup.isPlaying) {
      animationGroup.stop();
      animationGroup.reset();
    } else {
      animationGroup.loopAnimation = true;
      animationGroup.onAnimationGroupEndObservable.add(() => {
           animationGroup.play();
        })
      //animationGroup.enableBlending = true;
      animationGroup.play();
    }
  }
}

