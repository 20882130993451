<div class="fixed inset-0 flex items-center justify-center z-50" 
     [ngClass]="{'opacity-0 pointer-events-none': !isOpen, 'opacity-100 pointer-events-auto': isOpen}">
  <div class="fixed inset-0 bg-white dark:bg-black opacity-50" (click)="close()"></div>
  <div class="bg-white dark:bg-grey-800 rounded-lg shadow-lg p-6 w-full max-w-screen-xl mx-4 transform" 
       [ngClass]="{'scale-100': isOpen, 'scale-95': !isOpen}">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-semibold text-grey-900 dark:text-grey-100 font-bold uppercase">{{ title }}</h2>
      <button (click)="close()" class="text-grey-500 dark:text-grey-400 hover:text-grey-700 dark:hover:text-grey-300 focus:outline-none">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
    </div>
    <div class="mb-4 text-grey-900 dark:text-grey-100">
        <ng-content></ng-content>
    </div>
  </div>
</div>