import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Vector2 } from '@babylonjs/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrl: './slider.component.scss'
})
export class SliderComponent {
  @Input() value: number = 0.5;
  @Input() min: number = 0;
  @Input() max: number = 1;
  @Input() steps: number = 0.01;
  @Input() label: string = 'Label';
  @Output() onValueChange = new EventEmitter<number>();

  ngOnInit(): void {
    console.log('value', this.value);
  }

  onSliderValueChange(event: Event): void {
    const newValue = (event.target as HTMLInputElement).valueAsNumber;
    this.onValueChange.emit(newValue);
  }
}