import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IGarment } from 'src/app/services/rest/garment.service';

export interface IRightPanelItem {
  name: string;
  thumbnail_url?: string;
  selected: boolean;
  garment?: any;
}

@Component({
  selector: 'app-configurator-right-panel',
  templateUrl: './configurator-right-panel.component.html',
  styleUrl: './configurator-right-panel.component.scss',
})
export class ConfiguratorRightPanelComponent {
  @Input() hidden = true;

  get hiddenAndHasItems(): boolean {
    return this.hidden || this.items.length == 0;
  }

  public title: string = 'Title';
  public items: IRightPanelItem[] = [];
  public onSelect!: (item: IRightPanelItem) => void;
  public onConfirm?: (item: IRightPanelItem) => void;
  public onDeselect?: (item: IRightPanelItem) => void;
  public activeItem?: IRightPanelItem;

  @Output() activeItemChange: EventEmitter<IRightPanelItem> =
    new EventEmitter<IRightPanelItem>();

  show() {
    console.log('Showing right panel');
    this.hidden = false;
  }

  reset() {
    this.resetValues();
  }

  hide() {
    console.log('Hiding right panel');
    this.resetValues();
  }

  resetValues() {
    this.hidden = true;
    this.items = [];
    this.title = '';
    this.onConfirm = undefined;
    this.onDeselect = undefined;
    this.activeItem = undefined;
  }

  showSelectAndConfirm(
    title: string,
    items: IRightPanelItem[],
    onSelect: (item: IRightPanelItem) => void,
    onConfirm?: (item: IRightPanelItem) => void
  ) {
    this.title = title;
    this.items = items;
    this.items.forEach((item) => (item.selected = false));
    this.onSelect = onSelect;
    this.onDeselect = undefined;
    this.show();

    this.onConfirm = onConfirm;
  }

  showMultiSelect(
    title: string,
    items: IRightPanelItem[],
    onSelect: (item: IRightPanelItem) => void,
    onDeselect?: (item: IRightPanelItem) => void
  ) {
    this.title = title;
    this.items = items;
    this.onSelect = onSelect;
    this.onDeselect = onDeselect;
    this.show();
  }

  showGarments(
    title: string,
    garments: IGarment[],
    onSelect: (item: IRightPanelItem) => void,
    onDeselect?: (item: IRightPanelItem) => void
  ) {
    this.items = garments.map((garment) => ({
      name: garment.name,
      thumbnail_url: garment.thumbnail,
      selected: garment.equipped,
      garment: garment,
    }));
    this.showMultiSelect(title, this.items, onSelect, onDeselect);
  }

  removeItem(item: IRightPanelItem) {
    this.items = this.items.filter((i) => i !== item);
  }

  removeGarment(garment: IGarment) {
    this.items = this.items.filter((i) => i.garment !== garment);
  }

  triggerSelect(item: IRightPanelItem) {
    console.log("triggering select")
    this.activeItem = item;
    this.activeItemChange.emit(item);
    if (item.selected) return;
    this.onSelect(item);
    item.selected = true;

    if (!this.onDeselect) {
      this.items.forEach((i) => {
        i.selected = false;
      });
    }

  }

  triggerDeselect(event: Event, item: IRightPanelItem) {
    event.stopPropagation();
    item.selected = false;
    if(this.activeItem === item) {
      this.activeItem = undefined;
    }
    if (this.onDeselect) {
      this.onDeselect(item);
      console.log('deselect');
    }
  }

  confirmSelection() {
    console.log("confirming selection")
    if (this.onConfirm && this.activeItem) {
      this.onConfirm(this.activeItem);
    }
  }
}
