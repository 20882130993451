import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-auth-callback-page',
  templateUrl: './auth-callback-page.component.html',
  styleUrls: ['./auth-callback-page.component.scss']
})
export class AuthCallbackPageComponent {
  constructor(private route: ActivatedRoute, private authService: AuthService, private router: Router) {
    combineLatest([this.route.params, this.route.queryParams])
      .subscribe(([params, queryParams]) => {      
        this.authService.authenticateSocial(params['provider'], queryParams['code']).then((addedSocialAccount: boolean) => {
          router.navigate(['/dressup']);
        }, 
        () => {
          router.navigate(['/log-in']);
        });
      });
  }
}

