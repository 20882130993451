import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ViewportComponent } from '../../viewport/viewport.component';
import {
  ArcRotateCamera,
  AssetContainer,
  Bone,
  Color3,
  Color4,
  FresnelParameters,
  Material,
  Matrix,
  Mesh,
  PBRMaterial,
  Quaternion,
  Scene,
  SkeletonViewer,
  Space,
  StandardMaterial,
  TransformNode,
  Vector2,
  Vector3,
  serializeAsColor4,
} from '@babylonjs/core';
import { GltfImportService } from 'src/app/services/babylonjs/custom-assets/gltf-import.service';
import { GizmoService } from 'src/app/services/babylonjs/viewport/gizmo.service';
import { ReferenceModelTransferService } from 'src/app/services/babylonjs/custom-assets/reference-model-transfer.service';
import { ProgressSpinnerUpdateService } from 'src/app/services/babylonjs/viewport/progress-spinner-update.service';
import { ThemeSwitchService } from 'src/app/services/theme-switch.service';
import { DialogComponent } from '../../ui/dialog/dialog.component';
import {
  ConfiguratorRightPanelComponent,
  IRightPanelItem,
} from '../../ui/configurator-right-panel/configurator-right-panel.component';
import { TransformControlsComponent } from '../../ui/transform-controls/transform-controls.component';
import { ConfiguratorPropertyPanelComponent } from '../../ui/configurator-property-panel/configurator-property-panel.component';
import {
  GarmentService,
  IGarment,
  Slot,
} from 'src/app/services/rest/garment.service';
import { GltfExportService } from 'src/app/services/babylonjs/custom-assets/gltf-export.service';

interface IClothingCategory {
  name: string;
  alias: string;
  fileName: string;
  slot: number;
}

interface IGarmentSlotDot {
  slot: Slot;
  name: string;
  top: string;
  left: string;
  bone?: Bone;
  boneName: string;
}

@Component({
  selector: 'app-configurator-page',
  templateUrl: './configurator-page.component.html',
  styleUrls: ['./configurator-page.component.scss'],
})
export class ConfiguratorPageComponent implements AfterViewInit, OnInit {
  @ViewChild('viewportRef') viewport!: ViewportComponent;
  @ViewChild('propertyPanel')
  propertyPanel!: ConfiguratorPropertyPanelComponent;
  @ViewChild('rightPanel') rightPanel!: ConfiguratorRightPanelComponent;
  @ViewChild('transformControls')
  transformControls!: TransformControlsComponent;
  @ViewChild('uploadDialog') uploadDialog!: DialogComponent;
  @ViewChild('customAssetOptimizationIssuesDialog') customAssetOptimizationIssuesDialog!: DialogComponent;
  @ViewChild('fileInput') fileInput!: ElementRef;

  public equippedGarments: IGarment[] = [];
  public currentOptimizeCheck: { [key: string]: { passed: boolean, current_value: string, target_value: string} } = {};
  private uploadingCustomGarment: boolean = false;

  public slots: IGarmentSlotDot[] = [
    {
      slot: Slot.Head,
      name: 'Head',
      top: '0px',
      left: '0px',
      boneName: 'head',
    },
    {
      slot: Slot.Torso,
      name: 'Torso',
      top: '0px',
      left: '0px',
      boneName: 'chest',
    },
    {
      slot: Slot.Legs,
      name: 'Legs',
      top: '0px',
      left: '0px',
      boneName: 'hips',
    },
    {
      slot: Slot.Hands,
      name: 'Hands',
      top: '0px',
      left: '0px',
      boneName: 'hand.R',
    },
    {
      slot: Slot.Feet,
      name: 'Feet',
      top: '0px',
      left: '0px',
      boneName: 'root',
    },
  ];

  public categories: IClothingCategory[] = [
    { name: 'Dress', alias: 'dress', fileName: 'DRESS_A001', slot: Slot.Torso },
    {
      name: 'Fringer',
      alias: 'fringer',
      fileName: 'FRINGER_A002',
      slot: Slot.Torso,
    },
    { name: 'Hat', alias: 'hat', fileName: 'HAT_B001', slot: Slot.Head },
    {
      name: 'Jacket',
      alias: 'jacket',
      fileName: 'JACKET_B001',
      slot: Slot.Torso,
    },
    { name: 'Pants', alias: 'pants', fileName: 'PANTS_B001', slot: Slot.Legs },
    {
      name: 'Shoes',
      alias: 'shoes',
      fileName: 'SHOESTALL_B001',
      slot: Slot.Feet,
    },
    {
      name: 'Skirt straight',
      alias: 'skirtstraight',
      fileName: 'SKIRTSTRAIGHT_A001',
      slot: Slot.Legs,
    },
    {
      name: 'Tshirt',
      alias: 'tshirt',
      fileName: 'TSHIRT_B001',
      slot: Slot.Torso,
    },
  ];
  public loadedClothingCategoryReferenceMeshes: { [index: string]: Mesh } = {};

  private importedMesh: Mesh | undefined;
  public selectingBase: boolean = false;
  public uploading: boolean = false;
  public placing: boolean = false;
  public setup: boolean = false;
  public generating: boolean = false;
  public selectingCategory: boolean = false;
  public rotateSpeed: number = 0.01;
  public importName: string = '';

  public customFileName: string = "";
  private currentReferenceMesh: Mesh | undefined;

  public vrchat_password: string = '';
  public vrchat_email: string = '';
  public vrchat_loggedin: boolean = false;
  public slotsVisible: boolean = true;

  public currentCurvy: number = 0;
  public currentGender: number = 0;

  private baseMesh!: Mesh;

  public activeValues: { [index: string]: any } = {};

  public selectedBase: 'male' | 'female' = 'male';

  public rightPanelHidden: boolean = true;
  public transformControlsHidden: boolean = true;
  public bottomPanelHidden: boolean = false;

  public referenceMeshMaterial!: Material;

  constructor(
    private gltfImportService: GltfImportService,
    private gizmoService: GizmoService,
    private transferService: ReferenceModelTransferService,
    private updateProgressService: ProgressSpinnerUpdateService,
    private themeService: ThemeSwitchService,
    private garmentService: GarmentService,
    private gltfExportService: GltfExportService
  ) {}

  ngOnInit(): void {
    this.themeService.themeSubject.subscribe((theme) => {
      if (theme === 'dark') {
        this.applyDarkTheme();
      } else {
        this.applyLightTheme();
      }
    });
    this.activeValues['curvy-gender'] = new Vector2(1.0, 1.0);
  }

  private showSlots() {
    this.slotsVisible = true;
  }

  private hideSlots() {
    this.slotsVisible = false;
  }

  private updateSlotPositions() {
    const scene = this.viewport.scene;
    scene.onBeforeRenderObservable.add(() => {
      this.slots.forEach((slot) => {
        if (slot.bone) {
          const position = slot.bone.getAbsolutePosition();
          const screenPosition = this.worldToScreen(position, scene);
          slot.top = Math.round(screenPosition.y * 100) / 100 + 'px';
          slot.left = Math.round(screenPosition.x * 100) / 100 + 'px';
        }
      });
    });
  }

  private worldToScreen(
    position: Vector3,
    scene: Scene
  ): { x: number; y: number } {
    const engine = scene.getEngine();
    const camera = scene.activeCamera!;
    const width = engine.getRenderWidth();
    const height = engine.getRenderHeight();
    const vector = Vector3.Project(
      position,
      Matrix.Identity(),
      scene.getTransformMatrix(),
      camera.viewport.toGlobal(width, height)
    );
    return { x: vector.x, y: vector.y };
  }

  private assignBonesToSlots() {
    const scene = this.viewport.scene;
    const skeleton = this.baseMesh.skeleton;
    if (skeleton) {
      this.slots.forEach((slot) => {
        const bone: Bone | undefined = skeleton.bones.find(
          (b) => b.name === slot.boneName
        );
        if (bone) {
          slot.bone = bone;
        }
      });
    }
  }

  showGarmentsForSlot(slot: IGarmentSlotDot) {
    this.hideSlots();
    const filteredGarments = this.equippedGarments.filter(
      (garment) => garment.slot === slot.slot
    );
    // get garments from endpoint
    this.garmentService.getGarmentsBySlot(slot.slot).subscribe((garments) => {
      const unEquippedGarments = garments.filter(
        (garment) =>
          !this.equippedGarments.some((equipped) => equipped.id === garment.id)
      );

      const allGarments = [...filteredGarments, ...unEquippedGarments];
      this.rightPanel.showGarments(
        slot.name,
        allGarments,
        (item) => {
          if(!item.garment.equipped) {
            this.equipGarment(item.garment);
          }
        },
        (item) => {
          if(item.garment.equipped) {
            this.unequipGarment(item.garment);
          }
        }
      );
    });
  }

  equipGarment(garment: IGarment): Promise<Mesh> {
    return new Promise((resolve, reject) => {
      this.gltfImportService
        .importGltfFromUrl(garment.glb_file, this.viewport.scene)
        .then((assetContainer: AssetContainer) => {
          const mesh = this.getMeshFromAssetContainer(assetContainer);
          mesh.skeleton = this.baseMesh.skeleton;
          garment.mesh = mesh;
          garment.equipped = true;
          this.applyCurrentMorphTargets(mesh);
          this.equippedGarments.push(garment);
          resolve(mesh);
        });
    });
  }

  getMeshFromAssetContainer(assetContainer: AssetContainer) {
    const mesh = assetContainer.meshes[1] as Mesh;
    return mesh;
  }

  unequipGarment(garment: IGarment) {
    this.equippedGarments = this.equippedGarments.filter( (g) => g.id !== garment.id);
    garment.equipped = false;
    if(garment.mesh) {
      console.log("disposing garment mesh")
      garment.mesh.dispose();
    } else {
      console.warn("garment mesh not found");
    }
    if(!garment.id) {
      this.rightPanel.removeGarment(garment);
    }
  }

  onRightPanelActiveItemChange(item: IRightPanelItem) {
    if (item.garment) {
      this.propertyPanel.displayGarmentAttributes(item.garment);
    }
  }

  ngAfterViewInit(): void {
    this.watchActiveValues();
    const scene = this.viewport.scene;
    //scene.activeCamera!.position = new Vector3(0, 1.25, -4);
    this.gizmoService.setupGizmo(scene);
    this.loadBaseMesh().then((baseMesh: Mesh) => {
      this.baseMesh = baseMesh;
      this.applyBaseMeshMaterial(baseMesh, scene);
      this.startAnimation();
      this.changeAvatarColor(this.activeValues['avatar_color']);
      this.changeRoughness(this.activeValues['avatar_roughness']);
      this.changeMetallic(this.activeValues['avatar_metallic']);
      this.applyXYCoords(this.activeValues['curvy-gender']);
      //this.enableSkeletonViewer()
      this.referenceMeshMaterial = this.createReferenceMeshMaterial();
      //this.testAllReferenceMeshes();
      this.assignBonesToSlots();
      this.updateSlotPositions();
    });
  }

  applyDarkTheme() {
    if (
      this.activeValues['avatar_color'] == '#CCCCCC' ||
        this.activeValues['avatar_color'] == null
    ) {
      this.activeValues['avatar_roughness'] = 0.2;
      this.activeValues['avatar_metallic'] = 0.75;
      this.activeValues['avatar_color'] = '#010101';
    }
  }

  applyLightTheme() {
    if (
      this.activeValues['avatar_color'] == '#010101' ||
        this.activeValues['avatar_color'] == null
    ) {
      this.activeValues['avatar_roughness'] = 0.2;
      this.activeValues['avatar_metallic'] = 0.75;
      this.activeValues['avatar_color'] = '#CCCCCC';
    }
  }

  exportActiveGarment(fileName: string) {
    const garment = this.propertyPanel.activeGarment!;
    if(fileName.length == 0) {
      fileName = garment.name.toLowerCase().replace(/ /g, '_');
    }

    this.gltfExportService.exportMesh(garment.mesh!, fileName);
  }

  resetNavigation() {
    this.showSlots();
    this.propertyPanel.resetValues();
    this.rightPanel.resetValues();
  }

  watchActiveValues(): void {
    const activeValuesProxy = new Proxy(this.activeValues, {
      set: (target, property, value) => {
        target[property as string] = value;
        this.handleActiveValueChange(property as string, value);
        return true;
      },
    });

    this.activeValues = activeValuesProxy;
  }

  handleActiveValueChange(property: string, value: any): void {
    switch (property) {
      case 'curvy-gender':
        this.applyXYCoords(value as Vector2);
        break;
      case 'avatar_roughness':
        this.changeRoughness(value as number);
        break;
      case 'avatar_metallic':
        this.changeMetallic(value as number);
        break;
      case 'avatar_color':
        this.changeAvatarColor(value as string);
        break;
      default:
        console.log(`Unhandled property ${property} changed to ${value}`);
    }
  }

  changeMetallic(value: number) {
    (this.baseMesh.material as PBRMaterial).metallic = value;
  }

  changeRoughness(value: number) {
    (this.baseMesh.material as PBRMaterial).roughness = value;
  }

  changeAvatarColor(value: string) {
    (this.baseMesh.material as PBRMaterial).albedoColor =
      Color3.FromHexString(value);
  }

  addClothing(): void {
    this.setup = true;
    this.selectingBase = true;
    this.resetXYCoords();
    this.stopAndResetAnimation();
  }

  applyBaseMeshMaterial(baseMesh: Mesh, scene: Scene): void {
    const baseMat = new PBRMaterial('baseMat', scene);
    baseMat.albedoColor = new Color3(0, 0, 0);
    baseMat.roughness = 0.6;
    baseMat.metallic = 0.5;
    baseMesh.material = baseMat;
  }

  continueToUpload() {
    this.selectingBase = false;
    this.uploading = true;
  }

  freezeRotateCameraAroundObject(): void {
    this.rotateSpeed = 0;
  }

  continueRotateCameraAroundObject(): void {
    this.rotateSpeed = 0.01;
  }

  startUploadCustomGarment() {
    this.uploadingCustomGarment = true;
    this.hideSlots();
    this.resetXYCoords();
    this.stopAndResetAnimation();
    this.uploadDialog.close();
    this.gltfImportService
      .importGltfFromFile(
        this.fileInput.nativeElement.files[0],
        this.viewport.scene
      )
      .then((assetContainer: AssetContainer) => {
        this.importedMesh = assetContainer.meshes[1] as Mesh;
        const optimizeCheck = this.optimizeCheckAsset(assetContainer);
        this.currentOptimizeCheck = optimizeCheck;
        if(this.isOptimized(optimizeCheck)) {
          this.importedMesh.alwaysSelectAsActiveMesh = true;
          this.transformControls.transformMesh(this.importedMesh);
          this.rightPanel.hide();
          this.propertyPanel.hide();
        } else {
          this.customAssetOptimizationIssuesDialog.open();
          this.importedMesh.dispose();
        }
      });
  }

  isOptimized(optimizeCheck: { [key: string]: { passed: boolean, current_value: any, target_value: any} }): boolean {
    return Object.values(optimizeCheck).every(check => check.passed);
  }

  optimizeCheckAsset(assetContainer: AssetContainer): { [key: string ]: { passed: boolean, current_value: any, target_value: any} } {
    return {
      "Vertex count" : { passed: assetContainer.meshes[1].getTotalVertices() < 10000, current_value: assetContainer.meshes[1].getTotalVertices(), target_value: 10000 },
      "One mesh" : { passed: assetContainer.meshes.filter(mesh => mesh.name !== '__root__').length === 1, current_value: assetContainer.meshes.filter(mesh => mesh.name !== '__root__').map(mesh => mesh.name).join(', '), target_value: 1 },
      "One material" : { passed: assetContainer.materials.length == 1, current_value: assetContainer.materials.length, target_value: 1 },
      "Texture sizes below 2k" : {
        passed: assetContainer.materials[0].getActiveTextures().every(texture => texture.getSize().width < 2048 && texture.getSize().height < 2048),
        current_value: assetContainer.materials[0].getActiveTextures().map(texture => `${texture.getSize().width}x${texture.getSize().height}`),
        target_value: "Less than 2k"
      },
    }
  }

  resetObject(): void {
    if (!this.importedMesh) {
      return;
    }
    this.importedMesh.position = new Vector3(0, 0, 0);
    this.importedMesh.rotation = new Vector3(0, 0, 0);
    this.importedMesh.scaling = new Vector3(1, 1, 1);
  }

  public enablePlacing(): void {
    if (!this.importedMesh) {
      return;
    }
    this.uploading = false;
    this.placing = true;
    this.gizmoService.enable(this.importedMesh);
    this.freezeRotateCameraAroundObject();
  }

  placeObject(): void {
    this.gizmoService.disable();
    this.placing = false;
    this.continueRotateCameraAroundObject();
  }

  private createReferenceMeshMaterial = () => {
    const material = new StandardMaterial('referenceMat', this.viewport.scene);
    material.roughness = 0.2;
    material.alpha = 0.2;

    material.emissiveColor = new Color3(1, 0, 0);
    material.emissiveFresnelParameters = new FresnelParameters();
    material.emissiveFresnelParameters.bias = 0.01;
    material.emissiveFresnelParameters.power = 2;
    material.emissiveFresnelParameters.leftColor = new Color3(1, 1, 1);
    material.emissiveFresnelParameters.rightColor = new Color3(0, 0, 0);
    return material;
  };

  private loadBaseMesh(): Promise<Mesh> {
    return new Promise((resolve) => {
      this.gltfImportService
        .importGltf('assets/geom/', 'montegreen_B008.glb', this.viewport.scene)
        .then((assetContainer) => {
          if (assetContainer.animationGroups.length == 0) {
            console.error('No animations found in the imported base model');
          } else {
            assetContainer.animationGroups[0].stop();
          }
          const baseMesh: Mesh = assetContainer.meshes[1] as Mesh;
          //baseMesh.isVisible = false;

          resolve(baseMesh);
        });
    });
  }

  private getCategoryByAlias(alias: string): IClothingCategory | undefined {
    return this.categories.find((c) => c.alias == alias);
  }

  getClothingCategoryReferenceMesh(category_alias: string): Promise<Mesh> {
    if (this.loadedClothingCategoryReferenceMeshes[category_alias]) {
      return Promise.resolve(
        this.loadedClothingCategoryReferenceMeshes[category_alias]
      );
    }
    return this.loadClothingCategoryReferenceMesh(
      category_alias,
      this.baseMesh
    );
  }

  private testAllReferenceMeshes(): Promise<void> {
    return new Promise((resolve) => {
      this.categories.forEach((category) => {
        this.loadReferenceMesh(category.fileName, this.baseMesh).then(
          (referenceMesh) => {
            this.loadedClothingCategoryReferenceMeshes[category.alias] =
              referenceMesh;
            referenceMesh.isVisible = true;
          }
        );
      });
    });
  }

  private enableSkeletonViewer() {
    let skeletonView = new SkeletonViewer(
      this.baseMesh.skeleton!, //Target Skeleton
      this.baseMesh, //That skeletons Attached Mesh or a Node with the same globalMatrix
      this.viewport.scene, //The Scene scope
      false, //autoUpdateBoneMatrices?
      2 // renderingGroupId
    );
  }

  private loadClothingCategoryReferenceMesh(
    category_alias: string,
    baseMesh: Mesh
  ): Promise<Mesh> {
    return new Promise((resolve) => {
      const category = this.getCategoryByAlias(category_alias);
      if (!category) {
        console.error('Category alias not found: ' + category_alias);
        return;
      }

      this.loadReferenceMesh(category.fileName, baseMesh).then(
        (referenceMesh) => {
          this.loadedClothingCategoryReferenceMeshes[category_alias] =
            referenceMesh;
          referenceMesh.isVisible = true;
          referenceMesh.name = 'reference_mesh_' + category.alias;
          referenceMesh.renderingGroupId = 2;
          referenceMesh.material = this.referenceMeshMaterial;
          resolve(referenceMesh);
        }
      );
    });
  }

  private loadReferenceMesh(fileName: string, baseMesh: Mesh): Promise<Mesh> {
    return new Promise((resolve) => {
      this.gltfImportService
        .importGltf(
          'assets/geom/',
          `montegreen_${fileName}.glb`,
          this.viewport.scene
        )
        .then((assetContainer) => {
          if (assetContainer.animationGroups.length == 0) {
            console.warn(
              'No animations found in the imported ' + name + ' model'
            );
          } else {
            assetContainer.animationGroups[0].stop();
          }
          const clothingMesh: Mesh = assetContainer.meshes[1] as Mesh;
          this.shareSkeletonBetweenMeshes(baseMesh, clothingMesh);
          resolve(clothingMesh);
        });
    });
  }

  shareSkeletonBetweenMeshes(sourceMesh: Mesh, targetMesh: Mesh) {
    if (sourceMesh.skeleton) {
      targetMesh.skeleton = sourceMesh.skeleton;
    } else {
      console.error('Source mesh does not have a skeleton.');
    }
  }

  transferReferenceToGarmentMesh(category_alias: string, targetMesh: Mesh) {
    this.getClothingCategoryReferenceMesh(category_alias).then(
      (referenceMesh) => {
        if (this.currentReferenceMesh) {
          this.currentReferenceMesh.isVisible = false;
        }
        this.currentReferenceMesh = referenceMesh;
        referenceMesh.isVisible = true;
        this.updateProgressService.updateDescription('Generating...');

        targetMesh.bakeCurrentTransformIntoVertices();
        this.transferService.transfer(
          this.baseMesh,
          referenceMesh,
          targetMesh,
          this.selectedBase == 'female'
        );
        this.shareSkeletonBetweenMeshes(this.baseMesh, targetMesh);
        this.applyCurrentMorphTargets(targetMesh);
      }
    );
  }

  resetXYCoords(): void {
    if (this.selectedBase == 'female') {
      this.applyXYCoords(new Vector2(1, 1));
    } else {
      this.applyXYCoords(new Vector2(0, 1));
    }
  }

  applyXYCoords(coords: Vector2) {
    const curvy = 1 - coords.y;
    const gender = coords.x;

    if (this.currentCurvy != curvy || this.currentGender != gender) {
      this.currentCurvy = curvy;
      this.currentGender = gender;
      this.applyMorphTargets(this.baseMesh, gender, curvy);
      for (const garment of this.equippedGarments) {
        if (garment.mesh) {
          this.applyMorphTargets(garment.mesh, gender, curvy);
        }
      }
    }
  }

  applyMorphTargets(mesh: Mesh, gender: number, curvy: number) {
    this.updateMorphTarget(mesh, 0, gender * (1 - curvy));
    this.updateMorphTarget(mesh, 1, (1 - gender) * curvy);
    this.updateMorphTarget(mesh, 2, gender * curvy);
  }

  applyCurrentMorphTargets(mesh: Mesh) {
    this.applyMorphTargets(mesh, this.currentGender, this.currentCurvy);
  }

  updateMorphTarget(mesh: Mesh, index: number, value: number): void {
    const morphTargetManager = mesh.morphTargetManager!;
    if (!morphTargetManager) {
      console.error(`Mesh ${mesh.name} does not have a morph target manager.`);
      return;
    }
    let morphTarget = morphTargetManager.getTarget(index);
    morphTarget.influence = value;
  }

  stopAndResetAnimation() {
    const animationGroup = this.viewport.scene.animationGroups[1];
    animationGroup.stop();
    animationGroup.reset();

    this.baseMesh.skeleton!.bones.forEach((bone) => {
      bone.returnToRest();
    });
  }

  startAnimation() {
    const animationGroup = this.viewport.scene.animationGroups[1];
    animationGroup.weight = 1;
    animationGroup.start(true, 0.3);
  }

  animationIsPlaying() {
    return this.viewport.scene.animationGroups[1].isPlaying;
  }

  toggleAnimation() {
    if (this.animationIsPlaying()) {
      this.stopAndResetAnimation();
    } else {
      this.startAnimation();
    }
  }

  enableGizmo() {
    if (!this.importedMesh) {
      return;
    }
    this.gizmoService.enable(this.importedMesh);
  }

  onTransformConfirm() {
    this.transformControls.hide();
    this.propertyPanel.hide();
    this.startAnimation();
    this.rightPanel.showSelectAndConfirm(
      'Garment Type',
      this.categories.map((c) => ({
        name: c.name,
        selected: false,
      })),
      (item) => {
        this.transferReferenceToGarmentMesh(
          this.getCategoryByName(item.name).alias,
          this.importedMesh!
        );
      },
      (item) => {
        this.equippedGarments.push({
          name: item.name + ' (custom)',
          description: '',
          thumbnail: '',
          glb_file: '',
          mesh: this.importedMesh!,
          attributes: [],
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          slot: this.getCategoryByName(item.name).slot,
          equipped: true,
        });
        this.showSlots();
        this.hideRightPanel();
        if (this.currentReferenceMesh) {
          this.currentReferenceMesh.isVisible = false;
        }
        this.propertyPanel.show();
        this.propertyPanel.setActiveCategory('avatar');
        this.uploadingCustomGarment = false;
      }
    );
  }

  hideRightPanel() {
    this.rightPanel.hide();
  }

  getCategoryByName(name: string): IClothingCategory {
    return this.categories.find((c) => c.name == name)!;
  }

  onUploadClick() {
    this.uploadDialog.open();
  }
}
