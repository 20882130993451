import { Injectable } from '@angular/core';
import { Color3, Mesh, PBRMaterial, Scene, StandardMaterial, VertexBuffer, VertexData } from '@babylonjs/core';

@Injectable({
  providedIn: 'root'
})
export class BakedMorphtargetMeshService {
  // array with string keys for meshes
  private bakeMeshes: { [key: string]: Mesh} = {};

  constructor() { }

  getOrCreateBakedMorphtargetMesh(mesh: Mesh, morphtargetIndex: number) {
    if (this.bakeMeshes[mesh.name]) {
      return this.bakeMeshes[mesh.name];
    }
    const meshName = `${mesh.name}-${morphtargetIndex}-baked`;
    const morphTarget = mesh.morphTargetManager!.getTarget(morphtargetIndex)!;
    const tmpMesh = new Mesh(meshName, mesh.getScene());

    const vertexData = new VertexData();
    const positions = morphTarget.getPositions();
    const indices = mesh.getIndices()!;
    vertexData.positions = positions;
    vertexData.indices = indices;
    vertexData.applyToMesh(tmpMesh);

    const mat = new PBRMaterial("mat", mesh.getScene());
    mat.albedoColor = new Color3(0.5, 0.5, 0.5);
    tmpMesh.material = mat;
    tmpMesh.isVisible = false;

    this.bakeMeshes[meshName] = tmpMesh;


    return tmpMesh;
  }
}
