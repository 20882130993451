import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeSwitchService {
  public themeSubject = new BehaviorSubject<string>('light');

  constructor() {
    const savedTheme = this.getCookie('theme');
    if (savedTheme) {
      this.themeSubject.next(savedTheme);
      if (savedTheme === 'dark') {
        document.documentElement.classList.add('dark');
      }
    }
    console.log('themeSubject', this.themeSubject.value);
  }

  private getCookie(name: string): string | null {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  private setCookie(name: string, value: string, days: number): void {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  toggleTheme() {
    document.documentElement.classList.toggle('dark');
    const newTheme = this.themeSubject.value === 'light' ? 'dark' : 'light';
    this.setCookie('theme', newTheme, 365);
    this.themeSubject.next(
      this.themeSubject.value === 'light' ? 'dark' : 'light'
    );
  }
}
