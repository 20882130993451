import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfiguratorPageComponent } from './components/pages/configurator-page/configurator-page.component';
import { TransferTestPageComponent } from './components/pages/transfer-test-page/transfer-test-page.component';
import { LoggedOutPageComponent } from './components/pages/logged-out-page/logged-out-page.component';
import { NotFoundPageComponent } from './components/pages/not-found-page/not-found-page.component';
import { AccountPageComponent } from './components/pages/account-page/account-page.component';
import { authGuard } from './guards/auth.guard';
import { LogInPageComponent } from './components/pages/log-in-page/log-in-page.component';
import { AuthCallbackPageComponent } from './components/pages/auth-callback-page/auth-callback-page.component';


const routes: Routes = [
  { path: 'dressup', component: ConfiguratorPageComponent, canActivate: [authGuard]},
  { path: 'log-in', component: LogInPageComponent },
  { path: 'callback/:provider', component: AuthCallbackPageComponent },
  { path: 'not-found', component: NotFoundPageComponent },
  { path: 'logged-out', component: LoggedOutPageComponent },
  { path: 'account', component: AccountPageComponent, canActivate: [authGuard]},
  { path: 'transfer-test', component: TransferTestPageComponent },
  { path: '', redirectTo: 'dressup', pathMatch: 'full'},
  { path: '**', component: NotFoundPageComponent },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
