import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Vector2 } from '@babylonjs/core';

@Component({
  selector: 'app-xy-coord-input',
  templateUrl: './xy-coord-input.component.html',
  styleUrls: ['./xy-coord-input.component.scss']
})
export class XyCoordInputComponent implements AfterViewInit {

  @ViewChild('element') element!: ElementRef;
  @ViewChild('dotElement') dotElement!: ElementRef;
  @Output() onValueChange = new EventEmitter<Vector2>();

  private moveEnabled: boolean = false;
  @Input() values: Vector2 = new Vector2(0.5, 0.5); // Initialize at the center for a 0 to 1 range

  constructor() { }

  ngAfterViewInit(): void {
    this.updateCSS();
  }

  getBoundingClientRect(): DOMRect {
    return this.element.nativeElement.getBoundingClientRect();
  }

  @HostListener('pointerdown', ['$event'])
  onPointerDown(event: any): void {
    this.moveEnabled = true;
    this.onPointerMove(event);
  }

  @HostListener('pointerup', ['$event'])
  onPointerUp(event: any): void {
    this.moveEnabled = false;
  }

  @HostListener('pointermove', ['$event'])
  onPointerMove(event: any): void {
    if(this.moveEnabled) {
      const boundingRect = this.getBoundingClientRect();

      const rawX = (event.clientX - boundingRect.left) / this.element.nativeElement.offsetWidth;
      const rawY = (event.clientY - boundingRect.top) / this.element.nativeElement.offsetHeight;

      // Ensure the values are clamped between 0 and 1
      this.setValues(
        Math.max(0, Math.min(1, rawX)),
        Math.max(0, Math.min(1, rawY))
      );
    }
  }

  setValues(x: number, y: number): void {
    this.values.x = x;
    this.values.y = y;
    this.updateCSS();
    this.onValueChange.emit(this.values);
  }

  updateCSS() {
    // Directly apply the percentage based on the 0 to 1 range
    this.dotElement.nativeElement.style.left = `${this.values.x * 100}%`;
    this.dotElement.nativeElement.style.top = `${this.values.y * 100}%`;
  }

}

