/**
 * @internal
 */
export class IntersectionInfo {
    constructor(bu, bv, distance) {
        this.bu = bu;
        this.bv = bv;
        this.distance = distance;
        this.faceId = 0;
        this.subMeshId = 0;
    }
}
