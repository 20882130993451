export const environment = {
    production: false,
    use_hash_check: false,
    api_base_url: 'https://test.backend.codecouture.studio/api',
    google_oauth2_client_id: '85888613074-75vhskg6rj1po7qbp897tmkid8n48vvs.apps.googleusercontent.com',
    google_oauth2_redirect_uri: 'https://test.codecouture.studio/callback/google-oauth2/',
    instagram_oauth2_client_id: '',
    instagram_oauth2_redirect_uri: 'https://test.codecouture.studio/callback/instagram',
    facebook_oauth2_client_id: '',
    facebook_oauth2_redirect_uri: 'https://test.codecouture.studio/callback/instagram',
};

