import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocialLoginService {

  constructor() { }

  loginGoogle() {
    this.openPrompt(
      `https://accounts.google.com/o/oauth2/v2/auth?client_id=${environment.google_oauth2_client_id}&response_type=code&redirect_uri=${environment.google_oauth2_redirect_uri}&scope=https://www.googleapis.com/auth/userinfo.email`
    );
  }

  loginInstagram() {
    this.openPrompt(
      `https://api.instagram.com/oauth/authorize?client_id=${environment.instagram_oauth2_client_id}redirect_uri=${environment.instagram_oauth2_redirect_uri}&response_type=code&scope=user_profile`
    )
  }

  loginFacebook() {
    this.openPrompt(
      `https://www.facebook.com/v9.0/dialog/oauth?client_id=${environment.facebook_oauth2_client_id}&redirect_uri=${environment.facebook_oauth2_redirect_uri}&response_type=code&scope=email`
    )
  }

  openPrompt(URL: string) {
    window.location.href = URL;
  }
}
