<header class="sticky text-grey-600 dark:text-grey-300 flex flex-col z-10">
  <div class="container mx-auto flex flex-wrap px-4 md:px-0 py-2 flex-row items-center">
    <a class="flex title-font font-medium items-center justify-center text-grey-900 dark:text-grey-100">
      <object class="w-12 h-12 invert dark:invert-0 pointer-events-none" data="assets/img/svg/code_couture_logo.svg"
        type="image/svg+xml"></object>
    </a>
    <nav class="ml-auto flex flex-wrap items-center text-base justify-center gap-4">
      <!-- <a class="mr-5 hover:text-grey-900 dark:hover:text-grey-100">
        <object class="w-6 h-6 invert dark:invert-0" data="assets/img/svg/dressup.svg" type="image/svg+xml"></object>
      </a> -->
      <!-- <a class="mr-5 hover:text-grey-900 dark:hover:text-grey-100" *ngIf="authService.isLoggedIn">
        <object class="w-6 h-6 invert dark:invert-0" data="assets/img/svg/user.svg" type="image/svg+xml"></object>
      </a> -->
      <a (click)="onUploadClick()" class="hover:text-grey-900 dark:hover:text-grey-100 cursor-pointer" *ngIf="router.url === '/dressup'">
        <object class="w-6 h-6 invert dark:invert-0 pointer-events-none" data="assets/img/svg/upload.svg" type="image/svg+xml"></object>
      </a>

      <app-theme-switch></app-theme-switch>

      <a class="hover:text-grey-900 dark:hover:text-grey-100 cursor-pointer" (click)="authService.logOut()" *ngIf="authService.isLoggedIn">
        <object class="w-6 h-6 invert dark:invert-0 pointer-events-none" data="assets/img/svg/logout.svg" type="image/svg+xml"></object>
      </a>

<a class="ml-auto text-md mr-4 md:mr-0 text-black dark:text-white radius-lg flex flex-row gap-2 items-center">
      <span>1892</span>
      <object class="w-4 h-4 invert dark:invert-0 pointer-events-none" data="assets/img/svg/couture_credits.svg"
        type="image/svg+xml"></object>
    </a>
    </nav>
  </div>
  </header>
