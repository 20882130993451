<div class="absolute bottom-0 left-0 right-0 z-10" *ngIf="!hidden">
    <div class="container mx-auto flex flex-row p-4 items-center box-border justify-end gap-2">
        <div (click)="enableTranslate()" class="w-14 h-14 p-4 rounded-full bg-white dark:bg-grey-800 transition-border duration-300 " 
                [ngClass]="{'bg-grey-100 dark:bg-white': activeControl === 'translate'}">
            <object class="w-full h-full pointer-events-none"
                [ngClass]="{'invert-0 dark:invert': activeControl === 'translate', 'invert dark:invert-0': activeControl !== 'translate'}"
                data="assets/img/svg/translate.svg" type="image/svg+xml"></object>
        </div>
        <div (click)="enableRotate()" class="w-14 h-14 p-4 rounded-full bg-white dark:bg-grey-800 transition-border duration-300"
                [ngClass]="{'bg-grey-100 dark:bg-white': activeControl === 'rotate'}">
            <object class="w-full h-full pointer-events-none"
                [ngClass]="{'invert-0 dark:invert': activeControl === 'rotate', 'invert dark:invert-0': activeControl !== 'rotate'}"
                data="assets/img/svg/rotate.svg" type="image/svg+xml"></object>
        </div>
        <div (click)="enableScale()" class="w-14 h-14 p-4 rounded-full bg-white dark:bg-grey-800 transition-border duration-300 "
                [ngClass]="{'bg-grey-100 dark:bg-white': activeControl === 'scale'}">
            <object class="w-full h-full pointer-events-none"
                [ngClass]="{'invert-0 dark:invert': activeControl === 'scale', 'invert dark:invert-0': activeControl !== 'scale'}"
                data="assets/img/svg/scale.svg" type="image/svg+xml"></object>
        </div>
        <div (click)="confirmTransform()" class="w-14 h-14 p-4 rounded-full bg-green-700 transition-border duration-300 ">
            <object class="w-full h-full pointer-events-none"
                data="assets/img/svg/check.svg" type="image/svg+xml"></object>
        </div>
    </div>
</div>