import { Injectable } from '@angular/core';
import {
  ArcRotateCamera,
  Color3,
  Color4,
  DefaultRenderingPipeline,
  DirectionalLight,
  Engine,
  HemisphericLight,
  ImageProcessingConfiguration,
  Quaternion,
  Scene,
  TargetCamera,
  Vector3,
} from '@babylonjs/core';

@Injectable({
  providedIn: 'root',
})
export class SceneSetupService {
  constructor() {}

  setupScene(canvas: HTMLCanvasElement): Scene {
    const engine = this.createEngine(canvas);
    const scene = this.createScene(engine);
    const camera = this.createArcRotateCamera(scene);
    const pipeline = this.createDefaultPipeline(scene, camera);
    return scene;
  }

  createScene(engine: Engine): Scene {
    const scene = new Scene(engine);
    scene.ambientColor = Color3.White();
    scene.clearColor = new Color4(0, 0, 0, 0);
    scene.useRightHandedSystem = true;
    engine.runRenderLoop(() => {
      scene.render();
    });

    window.addEventListener('resize', () => {
      engine.resize();
    });

    return scene;
  }

  createCamera(scene: Scene): TargetCamera {
    const camera = new TargetCamera('mainCamera', new Vector3(0, 0, 0), scene);
    camera.rotationQuaternion = Quaternion.Identity();
    camera.fov = 0.9;
    camera.minZ = 0.1;
    camera.maxZ = 400;
    return camera;
  }

  createArcRotateCamera(scene: Scene): ArcRotateCamera {
    const camera = new ArcRotateCamera(
      'camera',
      Math.PI + Math.PI / 2,
      Math.PI / 2.3,
      2.5,
      new Vector3(0, 1.25, 0),
      scene
    );
    camera.minZ = 0.1;
    camera.lowerRadiusLimit = 0.5;
    camera.upperRadiusLimit = 5;
    camera.upperBetaLimit = Math.PI / 1.5;
    camera.wheelPrecision = 200;

    camera.attachControl(true);
    scene.activeCamera = camera;
    return camera;
  }

  createHemisphericLight(scene: Scene): HemisphericLight {
    const light = new HemisphericLight('light', new Vector3(0, 1, 0), scene);
    light.intensity = 0;
    return light;
  }

  createDirectionalLight(scene: Scene): DirectionalLight {
    const light = new DirectionalLight(
      'light',
      new Vector3(-0.5, -0.3, -0.7),
      scene
    );
    light.diffuse = new Color3(1, 1, 1);
    light.specular = new Color3(0.5, 0.5, 0.5);
    light.intensity = 0.15;
    return light;
  }

  createEngine(canvas: HTMLCanvasElement): Engine {
    return new Engine(canvas, true, {
      stencil: false,
      doNotHandleTouchAction: true,
    });
  }

  createDefaultPipeline(
    scene: Scene,
    camera: TargetCamera
  ): DefaultRenderingPipeline {
    const pipeline = new DefaultRenderingPipeline('default', false, scene, [
      camera,
    ]);
    pipeline.samples = 4;
    pipeline.fxaaEnabled = true;
    pipeline.bloomEnabled = true;
    pipeline.bloomThreshold = 0.8;
    pipeline.bloomWeight = 0.3;
    pipeline.bloomKernel = 64;
    pipeline.bloomScale = 0.5;
    pipeline.imageProcessingEnabled = true;
    pipeline.imageProcessing.exposure = 0.9;
    pipeline.imageProcessing.toneMappingEnabled = true;
    pipeline.imageProcessing.toneMappingType = ImageProcessingConfiguration.TONEMAPPING_STANDARD;

    return pipeline;
  }
}
