import { Component } from '@angular/core';

@Component({
  selector: 'app-logged-out-page',
  templateUrl: './logged-out-page.component.html',
  styleUrls: ['./logged-out-page.component.scss']
})
export class LoggedOutPageComponent {

}
