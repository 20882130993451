import { Injectable } from '@angular/core';
import { Mesh, Scene } from '@babylonjs/core';
import { GLTF2Export, IExportOptions } from '@babylonjs/serializers';

@Injectable({
  providedIn: 'root'
})
export class GltfExportService {

  constructor() { }

  exportScene(scene: Scene, name: string) {
    console.log("exporting")


    let options: IExportOptions = {
      shouldExportNode: function (node: any) {
        return !node.name.includes("-baked") && !node.name.includes("reference_mesh") && !node.name.includes("noexport")
      },
    };

    GLTF2Export.GLBAsync(
      scene,
      name,
      options
    ).then((glb: any) => {
      console.log("download")
      glb.downloadFiles();
    });
  }

  exportMesh(mesh: Mesh, name: string) {
    let options: IExportOptions = {
      shouldExportNode: function (node: any) {
        return node.name === mesh.name
      },
    };

    GLTF2Export.GLBAsync(
      mesh.getScene(),
      name,
      options
    ).then((glb: any) => {
      console.log("download")
      glb.downloadFiles();
    });
  }
}
