import { Injectable } from '@angular/core';
import { FloatArray, Mesh, Vector3 } from '@babylonjs/core';

@Injectable({
  providedIn: 'root'
})
export class FindNearestVerticesService {

  constructor() { }

  // Use squared distance to avoid costly square root computation
  squaredDistance = (x1: number, y1: number, z1: number, x2: number, y2: number, z2: number): number => {
    const dx = x2 - x1;
    const dy = y2 - y1;
    const dz = z2 - z1;
    return dx * dx + dy * dy + dz * dz;
  };

  findNearestVertices(sourcePositions: FloatArray, targetPositions: FloatArray): number[] {
    console.log("finding nearest vertices...");
    const nearestVertexIndices = [];

    for (let i = 0; i < targetPositions.length; i += 3) {
      let nearestIndex = 0;
      let shortestSquaredDistance = Infinity;

      for (let j = 0; j < sourcePositions.length; j += 3) {
        const currentSquaredDistance = this.squaredDistance(
          targetPositions[i], targetPositions[i + 1], targetPositions[i + 2],
          sourcePositions[j], sourcePositions[j + 1], sourcePositions[j + 2]
        );

        if (currentSquaredDistance < shortestSquaredDistance) {
          shortestSquaredDistance = currentSquaredDistance;
          nearestIndex = j / 3;
        }
      }
      nearestVertexIndices.push(nearestIndex);
    }
    return nearestVertexIndices;
  }
}
