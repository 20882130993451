import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ViewportComponent } from '../../viewport/viewport.component';
import { Mesh, TransformNode } from '@babylonjs/core';
import { GizmoService } from 'src/app/services/babylonjs/viewport/gizmo.service';

@Component({
  selector: 'app-transform-controls',
  templateUrl: './transform-controls.component.html',
  styleUrl: './transform-controls.component.scss'
})
export class TransformControlsComponent {
  @Input() viewport!: ViewportComponent;
  @Input() hidden = true;
  @Output() onTransformConfirm = new EventEmitter();

  public activeControl: 'translate' | 'rotate' | 'scale' | 'none' = 'none';

  constructor(private gizmoService: GizmoService) {}

  enableTranslate() {
    this.gizmoService.enablePosition();
    this.activeControl = 'translate';
  }

  enableRotate() {
    this.gizmoService.enableRotate();
    this.activeControl = 'rotate';
  }

  enableScale() {
    this.gizmoService.enableScale();
    this.activeControl = 'scale';
  }

  confirmTransform() {
    this.gizmoService.disable();
    this.onTransformConfirm.emit();
    this.activeControl = 'none';
  }

  transformMesh(mesh: Mesh) {
    this.gizmoService.enable(mesh);
    this.show();
  }

  show() {
    console.log("Showing transform controls");
    this.hidden = false;
  }

  hide() {
    console.log("Hiding transform controls");
    this.hidden = true;
  }
}
