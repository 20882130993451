import { Component } from '@angular/core';
import { SocialLoginService } from 'src/app/services/social-login.service';

@Component({
  selector: 'app-log-in-page',
  templateUrl: './log-in-page.component.html',
  styleUrls: ['./log-in-page.component.scss']
})
export class LogInPageComponent {

  public email: string = "";
  public password: string = "";

  constructor(private socialLogin: SocialLoginService) {}

  loginGoogle() {
    this.socialLogin.loginGoogle();
  }

  loginInstagram() {
    this.socialLogin.loginInstagram();
  }

  loginFacebook() {
    this.socialLogin.loginFacebook();
  }
}
