import { Injectable } from '@angular/core';
import {
  BackgroundMaterial,
  Color3,
  CubeTexture,
  HDRCubeTexture,
  Mesh,
  MeshBuilder,
  Scene,
  StandardMaterial,
  Texture,
} from '@babylonjs/core';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentLightingSetupService {

  private skyDome!: Mesh;

  constructor() {}

  loadHDR(scene: Scene, url: string): Promise<void> {
    return new Promise((success) => {
      const environmentTexture = new HDRCubeTexture(url, scene, 1024, false, true, false, false, undefined, undefined, true);
      scene.environmentTexture = environmentTexture;
      const skydome = this.getSkyDome(scene);
      (skydome.material! as BackgroundMaterial).reflectionTexture = environmentTexture;

      environmentTexture.onLoadObservable.add(() => {
        environmentTexture.gammaSpace = false;
        success();
      });
    });
  }

  createSkyDome(scene: Scene) {
    const size = 1000;
    const skydome = MeshBuilder.CreateBox(
      'noexport_sky',
      { size, sideOrientation: Mesh.BACKSIDE },
      scene
    );
    skydome.position.y = (size / 2) - 0.1;
    skydome.receiveShadows = true;

    const sky = new BackgroundMaterial('skyMaterial', scene);
    sky.enableGroundProjection = true;
    sky.projectedGroundRadius = 200;
    sky.projectedGroundHeight = 3;
    sky.reflectionBlur = 0.13;
    skydome.material = sky;

    return skydome;
  }

  getSkyDome(scene: Scene): Mesh {
    if(!this.skyDome) {
      this.skyDome = this.createSkyDome(scene);
    }
    return this.skyDome;
  }

  loadENV(scene: Scene, url: string): Promise<void> {
    return new Promise((success, error) => {
      const environmentTexture = CubeTexture.CreateFromPrefilteredData( url, scene);
      scene.environmentTexture = environmentTexture;
      const skydome = this.getSkyDome(scene);
      (skydome.material! as BackgroundMaterial).reflectionTexture = environmentTexture;

      environmentTexture.onLoadObservable.add(() => {
        environmentTexture.gammaSpace = false;
        success();
      });
    });
  }
}
