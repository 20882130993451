import { Injectable } from '@angular/core';
import { FloatArray, Mesh, MorphTarget, MorphTargetManager, Ray, Vector3 } from '@babylonjs/core';
import { FindNearestVerticesService } from './find-nearest-vertices.service';
import { InspectorService } from '../viewport/inspector.service';
import { BakedMorphtargetMeshService } from './baked-morphtarget-mesh.service';
import { ProgressSpinnerUpdateService } from '../viewport/progress-spinner-update.service';

@Injectable({
  providedIn: 'root'
})
export class TransferMorphtargetsService {

  constructor(private findNearestVerticesService: FindNearestVerticesService, private inspectorService: InspectorService, private bakedMorphTargetMeshService: BakedMorphtargetMeshService, private progressUpdateService: ProgressSpinnerUpdateService) { }

  createCustomMorphTargetBasedOnNearestVertices(
    baseMesh: Mesh,
    targetMesh: Mesh,
    sourceMesh: Mesh,
    sourceMorphTargetIndex: number,
    nearestVerticesIndices: number[],
    skipAdjustVerticesOutsideMesh: boolean = false
  ): void {
    const sourceMorphTarget = sourceMesh.morphTargetManager!.getTarget(sourceMorphTargetIndex);
    console.log("-- creating custom morph target: (" + sourceMorphTargetIndex + ")" + sourceMorphTarget.name);

    const targetMorphTarget = new MorphTarget(sourceMorphTarget.name, 1, targetMesh.getScene());
    targetMesh.morphTargetManager?.addTarget(targetMorphTarget);

    const sourceMorphPositions = sourceMorphTarget.getPositions() ?? [];
    const targetBasePositions = targetMesh.getVerticesData("position") ?? [];
    const sourceBasePositions = sourceMesh.getVerticesData("position") ?? [];
    const modifiedPositions = new Array(targetBasePositions.length);

    nearestVerticesIndices.forEach((sourceIndex, targetIndex) => {
      const baseIndex = sourceIndex * 3;
      const targetBaseIndex = targetIndex * 3;

      const deformation = [
        sourceMorphPositions[baseIndex] - targetBasePositions[targetBaseIndex] - (sourceBasePositions[baseIndex] - targetBasePositions[targetBaseIndex]),
        sourceMorphPositions[baseIndex + 1] - targetBasePositions[targetBaseIndex + 1] - (sourceBasePositions[baseIndex + 1] - targetBasePositions[targetBaseIndex + 1]),
        sourceMorphPositions[baseIndex + 2] - targetBasePositions[targetBaseIndex + 2] - (sourceBasePositions[baseIndex + 2] - targetBasePositions[targetBaseIndex + 2])
      ];

      modifiedPositions[targetBaseIndex] = targetBasePositions[targetBaseIndex] + deformation[0];
      modifiedPositions[targetBaseIndex + 1] = targetBasePositions[targetBaseIndex + 1] + deformation[1];
      modifiedPositions[targetBaseIndex + 2] = targetBasePositions[targetBaseIndex + 2] + deformation[2];
    });

    if(!skipAdjustVerticesOutsideMesh) {
      this.adjustVerticesOutsideMesh(modifiedPositions, baseMesh, sourceMorphTargetIndex);
    }

    targetMorphTarget.setPositions(modifiedPositions);
  }

  adjustVerticesOutsideMesh(
    modifiedPositions: number[],
    baseMesh: Mesh,
    sourceMorphTargetIndex: number | null
  ): number[] {
    let baseMorphPositions: FloatArray = [];
    let normals: FloatArray = [];

    let usedBaseMesh: Mesh;
    if(sourceMorphTargetIndex !== null) {
      const sourceMorphTarget = baseMesh.morphTargetManager!.getTarget(sourceMorphTargetIndex);
      baseMorphPositions = sourceMorphTarget.getPositions() ?? [];
      usedBaseMesh = this.bakedMorphTargetMeshService.getOrCreateBakedMorphtargetMesh( baseMesh, sourceMorphTargetIndex);
      normals = usedBaseMesh.getVerticesData("normal") ?? [];
      console.log("adjusting vertices outside mesh with morphtarget " + sourceMorphTarget.name);
    } else {
      baseMorphPositions = baseMesh.getVerticesData("position") ?? [];
      normals = baseMesh.getVerticesData("normal") ?? [];
      usedBaseMesh = baseMesh;
      console.log("adjusting vertices outside mesh without morphtarget");
    }

    const nearestVerticesIndices = this.findNearestVerticesService.findNearestVertices(
      baseMorphPositions,
      modifiedPositions
    );

    nearestVerticesIndices.forEach((sourceIndex, targetIndex) => {
      const targetBaseIndex = targetIndex * 3;
      const sourceBaseIndex = sourceIndex * 3;

      // Create a vector for the modified position
      const modifiedPosition = new Vector3(
        modifiedPositions[targetBaseIndex],
        modifiedPositions[targetBaseIndex + 1],
        modifiedPositions[targetBaseIndex + 2]
      );

      const normal = new Vector3(
        normals[sourceBaseIndex],
        normals[sourceBaseIndex + 1],
        normals[sourceBaseIndex + 2]
      );

      this.pushVerticesOutsideMesh(targetBaseIndex, sourceBaseIndex, modifiedPositions, modifiedPosition, normal, usedBaseMesh, false);

    });

    return modifiedPositions;
  }

  pushVerticesOutsideMesh(targetBaseIndex: number, sourceBaseIndex: number, modifiedPositions: number[], modifiedPosition: Vector3, normal: Vector3, baseMesh: Mesh, showNormalLines: boolean = false): void {
    const epsilon = 0.001;

    if (this.checkIntersectionWithSourceMesh(modifiedPosition, normal, baseMesh)) {
      if(showNormalLines) {
        this.inspectorService.addGreenNormalLine(baseMesh, modifiedPosition, normal);
      }
      modifiedPositions[targetBaseIndex] += normal.x * epsilon;
      modifiedPositions[targetBaseIndex + 1] += normal.y * epsilon;
      modifiedPositions[targetBaseIndex + 2] += normal.z * epsilon;

      const newModifiedPosition = new Vector3(
        modifiedPositions[targetBaseIndex],
        modifiedPositions[targetBaseIndex + 1],
        modifiedPositions[targetBaseIndex + 2]
      )
      this.pushVerticesOutsideMesh(targetBaseIndex, sourceBaseIndex, modifiedPositions, newModifiedPosition, normal, baseMesh);
    } else {
      if(showNormalLines) {
        this.inspectorService.addRedNormalLine(baseMesh, modifiedPosition, normal);
      }
    }
  }

  checkIntersectionWithSourceMesh(position: Vector3, normal: Vector3, baseMesh: Mesh): boolean {
    const offset = 0.02;
    const adjustedPosition = position.subtract(normal.scale(offset));
    return new Ray(adjustedPosition, normal, 0.05).intersectsMesh(baseMesh, true).hit;
  }

  transferMorphTargets(baseMesh: Mesh, sourceMesh: Mesh, targetMesh: Mesh, nearestVerticesIndices: number[], femaleBase: boolean = false): void {
    console.log("--- creating morph targets")
    const sourceMTM = sourceMesh.morphTargetManager;
    if (!sourceMTM) {
      console.error("Source mesh does not have a morph target manager.");
      return;
    }

    targetMesh.morphTargetManager = new MorphTargetManager(targetMesh.getScene());

    for (let i = 0; i < sourceMTM.numTargets; i++) {
      this.progressUpdateService.updateDescription("Transfering morph targets " + (i + 1) + "/" + sourceMTM.numTargets);
      this.createCustomMorphTargetBasedOnNearestVertices(
        baseMesh,
        targetMesh,
        sourceMesh,
        i,
        nearestVerticesIndices,
        femaleBase && i == 0
      );
    }

    this.inspectorService.createNormalLineSystem(targetMesh.getScene());
  }

}
