import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export class User {
  constructor(
    public id: number,
    public credits: number,
    public social_auth: any
  ){}
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isLoggedIn: boolean = false;
  public user: User | undefined = undefined;
  public balance: number = 0;
  public hasCredits: boolean = false;
  public hasPromptCredits: boolean = false;
  private LOGGED_IN_KEY = "logged_in";
  private ACCESS_TOKEN_KEY = "access_token";
  private REFRESH_TOKEN_KEY = "refresh_token";
  private USER_KEY = "user";

  public onLoginChange: Subject<boolean> = new Subject<boolean>();

  constructor(public http: HttpClient, private router: Router) {
    this.isLoggedIn = localStorage.getItem(this.LOGGED_IN_KEY) != null;
    this.user = this.getUser();
    this.hasCredits = this.user?.credits > 0;
    this.hasPromptCredits = this.user?.credits > 3;
  }

  setJWTTokens(token: string, refresh: string): void {
    localStorage.setItem(this.ACCESS_TOKEN_KEY, token);
    localStorage.setItem(this.REFRESH_TOKEN_KEY, refresh);
    this.setLoggedIn(true);
  }

  private getUser(): User {
    return JSON.parse(localStorage.getItem(this.USER_KEY)!);
  }

  private setUser(user: any): void {
    localStorage.setItem(this.USER_KEY, JSON.stringify(user))!;
    this.user = user;
    this.hasCredits = user.credits > 0;
    this.hasPromptCredits = user.credits > 3;
  }

  private storeUser(): void {
    localStorage.setItem(this.USER_KEY, JSON.stringify(this.user))!;
  }

  getJWTAccessToken(): string {
    return localStorage.getItem(this.ACCESS_TOKEN_KEY)!;
  }

  getJWTRefreshToken(): string {
    return localStorage.getItem(this.REFRESH_TOKEN_KEY)!;
  }

  getNewAccessToken(): Observable<string> {
    return this.http
      .post(
        `${environment.api_base_url}/auth/refresh/`,
        { refresh: this.getJWTRefreshToken() },
        { observe: 'response' }
      )
      .pipe(
        share(),
        map((res: any) => {
          localStorage.setItem(this.ACCESS_TOKEN_KEY, res.body['access']);
          return this.getJWTAccessToken();
        })
      );
  }

  logOut() {
    const thash = localStorage.getItem('test-hash');
    localStorage.clear();
    if(thash) localStorage.setItem('test-hash', thash);
    this.router.navigate(['/log-in']);
    this.setLoggedIn(false);
  }

  getUserDetails(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.api_base_url}/account/`).subscribe((response) => {
        this.setUser(response);
        resolve();
      });
    })
  }

  getBalance(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.api_base_url}/balance/`).subscribe((response: any) => {
        this.user!.credits = response.credits;
        resolve();
      });
    })
  }

  reduceCredits(amount: number) {
    this.user!.credits = Math.max(this.user!.credits - amount, 0);
    this.hasCredits = this.user!.credits > 0;
    this.hasPromptCredits = this.user!.credits > 3;
    this.storeUser();
  }

  hasProvider(provider: string): boolean {
    return this.user! && this.user.social_auth.filter((e: any) => e.provider === provider).length > 0;
  }

  setLoggedIn(loggedIn: boolean) {
    if (loggedIn) {
      localStorage.setItem(this.LOGGED_IN_KEY, "true");
    } else {
      localStorage.removeItem(this.LOGGED_IN_KEY);
    }
    this.isLoggedIn = loggedIn;
    this.onLoginChange.next(loggedIn);
  }

  authenticateSocial(provider: string, code: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const body = {
        code: code,
        provider: provider,
        redirect_uri: `${location.origin}/callback/${provider}/`,
        email: "test@test.com"
      }
      let alreadyLoggedIn = false;
      if(this.isLoggedIn) {
        alreadyLoggedIn = true;
      }
      this.http.post(`${environment.api_base_url}/auth/login/social/jwt-pair/`, body).subscribe((response: any) => {
        this.setJWTTokens(response['token'], response['refresh']);
        Promise.all(
          [
            this.getUserDetails(),
          ]).then(() => {
          resolve(alreadyLoggedIn);
        })
      }, (err) => {
        reject(err);
      })
    });
  }
}
