import { Injectable } from '@angular/core';
import { Mesh, MorphTargetManager, Vector3 } from '@babylonjs/core';
import { TransferMorphtargetsService } from './transfer-morphtargets.service';
import { TransferSkinweightsService } from './transfer-skinweights.service';
import { FindNearestVerticesService } from './find-nearest-vertices.service';
import { ProgressSpinnerUpdateService } from '../viewport/progress-spinner-update.service';

@Injectable({
  providedIn: 'root'
})
export class ReferenceModelTransferService {

  constructor(private findNearestVerticesService: FindNearestVerticesService, private transferMorphtargetsService: TransferMorphtargetsService, private transferSkinWeightsService: TransferSkinweightsService, private progressUpdateService: ProgressSpinnerUpdateService) { }

  convertFemaleBaseToMaleBase(baseMesh: Mesh, sourceMesh: Mesh, targetMesh: Mesh, nearestVerticesIndices: number[]) {
    console.log("converting female clothing to male base");
    const sourceMorphTarget = sourceMesh.morphTargetManager!.getTarget(0);
    const femaleMorphPositions = sourceMorphTarget.getPositions() ?? [];
    const sourcePositions = sourceMesh.getVerticesData("position")!;
    const targetPositions = targetMesh.getVerticesData("position")! as number[];

    const femaleMorphDifferences = new Float32Array(femaleMorphPositions.length);

    // calculate the difference between the female morph positions and the male morph positions
    for (let i = 0; i < femaleMorphPositions.length; i++) {
      femaleMorphDifferences[i] = sourcePositions[i] - femaleMorphPositions[i];
    }

    // apply the opposite difference to make the mesh look like the male
    for (let i = 0; i < nearestVerticesIndices.length; i++) {
      const sourceIndex = nearestVerticesIndices[i] * 3;
      const targetIndex = i * 3;

      // Apply the inverse of the morph target differences
      targetPositions[targetIndex] += femaleMorphDifferences[sourceIndex];
      targetPositions[targetIndex + 1] += femaleMorphDifferences[sourceIndex + 1];
      targetPositions[targetIndex + 2] += femaleMorphDifferences[sourceIndex + 2];

    }
    this.transferMorphtargetsService.adjustVerticesOutsideMesh(targetPositions, baseMesh, null);

    targetMesh.setVerticesData("position", targetPositions, true);
  }

  public getFemaleMorphPositions(sourceMesh: Mesh) {
    const morphTargetManager = sourceMesh.morphTargetManager;
    const morphTarget = morphTargetManager!.getTarget(0);
    return morphTarget.getPositions();
  }

  resetTargetMesh(targetMesh: Mesh) {
    // reset the morphtargets
    const morphTargetManager = targetMesh.morphTargetManager;
    if (!morphTargetManager) {
      console.error(`Mesh ${targetMesh.name} does not have a morph target manager.`);
      return;
    }
    morphTargetManager.dispose();
    targetMesh.morphTargetManager = new MorphTargetManager(targetMesh.getScene());
  }

  transfer(baseMesh: Mesh, sourceMesh: Mesh, targetMesh: Mesh, femaleBase: boolean): void {
    console.log("female base: ", femaleBase);
    this.resetTargetMesh(targetMesh);

    let sourcePositions = sourceMesh.getVerticesData("position")!;
    if(femaleBase) {
      sourcePositions = this.getFemaleMorphPositions(sourceMesh)!;
    }
    this.progressUpdateService.updateDescription("Finding nearest vertices for the base model...");
    const nearestVerticesIndices = this.findNearestVerticesService.findNearestVertices(
      sourcePositions,
      targetMesh.getVerticesData('position')!);

    if(femaleBase) {
      this.progressUpdateService.updateDescription("Converting clothing to male...");
      this.convertFemaleBaseToMaleBase(baseMesh, sourceMesh, targetMesh, nearestVerticesIndices);
    }

    this.transferMorphtargetsService.transferMorphTargets(baseMesh, sourceMesh, targetMesh, nearestVerticesIndices, femaleBase);
    this.transferSkinWeightsService.transferSkinWeights(sourceMesh, targetMesh, nearestVerticesIndices);
  }
}
