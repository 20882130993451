import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

  constructor(public router: Router, public authService: AuthService) {}

  @Output() uploadClick = new EventEmitter<void>();

  onUploadClick() {
    this.uploadClick.emit();
  }
}
