import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { ProgressSpinnerUpdateService } from 'src/app/services/babylonjs/viewport/progress-spinner-update.service';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent {
  public loadingDescription: string = '';

  constructor(private progressSpinnerUpdateService: ProgressSpinnerUpdateService) {
    this.progressSpinnerUpdateService.onDescriptionUpdate.subscribe((description: string) => {
      this.loadingDescription = description;
    });
  }
}
