<ng-container *ngIf="!hiddenAndHasItems">
  <div class="w-full bg-grey-700 dark:bg-white text-white dark:text-black py-1 rounded-tl-2xl md:rounded-t-2xl text-xs font-bold uppercase flex items-center justify-center">
    <object class="w-5 h-5 pointer-events-none dark:invert" data="assets/img/svg/keyframe.svg" type="image/svg+xml"></object>
    {{title}}
  </div>
  <div
    class="p-3 bg-grey-200 dark:bg-grey-800 text-black dark:text-white grid grid-cols-1 gap-4 overflow-x-visible overflow-y-auto snap-y snap-mandatory rounded-bl-2xl md:rounded-none md:rounded-b-2xl pointer-events-auto">
    <div *ngFor="let item of items" (click)="triggerSelect(item)"
      class="border-2 border-grey-500 dark:border-grey-900 cursor-pointer relative snap-always snap-center bg-white text-black dark:bg-grey-700 dark:text-white h-12 w-12 rounded-full"
      [ngClass]="{'border-grey-600 dark:border-grey-400': item.selected && activeItem !== item, 'border-grey-800 dark:border-white': activeItem === item}"
    >
      <ng-container *ngIf="item.thumbnail_url; else nameTemplate">
        <ng-container *ngIf="item.thumbnail_url.endsWith('.svg'); else imgTemplate">
          <object [data]="item.thumbnail_url" type="image/svg+xml" class="w-full h-full object-cover rounded-full"></object>
        </ng-container>
        <ng-template #imgTemplate>
          <img crossorigin="anonymous" [src]="item.thumbnail_url" alt="thumbnail" class="w-full h-full object-cover rounded-full">
        </ng-template>
      </ng-container>
      <ng-template #nameTemplate>
        <span class="w-full h-full flex items-center justify-center font-xs text-center text-xs break-words">{{
          item.name }}</span>
      </ng-template>
      <div *ngIf="item.selected" (click)="triggerDeselect($event, item)"
        class="absolute -top-1 -right-1 w-4 h-4 bg-black text-white dark:bg-white dark:text-black flex items-center justify-center rounded-full cursor-pointer">
        &times;
      </div>
      <div *ngIf="activeItem === item"
        class="absolute inset-y-1/2 -translate-y-1/2 -translate-x-1/2 left-0 bg-white h-6 w-6 p-1 rounded-full flex items-center justify-center">
        <object class="w-full h-full pointer-events-none dark:invert" data="assets/img/svg/edit.svg" type="image/svg+xml"></object>
      </div>
    </div>
  </div>
  <div *ngIf="!hidden && onConfirm" (click)="confirmSelection()"
    class="w-14 h-14 p-4 mt-4 rounded-full bg-green-700 transition-border duration-300 pointer-events-auto">
    <object class="w-full h-full pointer-events-none" data="assets/img/svg/check.svg" type="image/svg+xml"></object>
  </div>
</ng-container>
