import { Component, Input } from '@angular/core';
import { Vector2 } from '@babylonjs/core';
import { ViewportComponent } from '../../viewport/viewport.component';
import { GltfExportService } from 'src/app/services/babylonjs/custom-assets/gltf-export.service';
import { IGarment } from 'src/app/services/rest/garment.service';

interface IPropertyPanelCategory {
  name: string;
  alias: string;
}

@Component({
  selector: 'app-configurator-property-panel',
  templateUrl: './configurator-property-panel.component.html',
})
export class ConfiguratorPropertyPanelComponent {
  @Input() activeValues: { [index: string]: any } = {};
  @Input() viewport!: ViewportComponent;

  public hidden: boolean = false;

  public categories: IPropertyPanelCategory[] = [
    { name: 'Avatar', alias: 'avatar' },
    { name: 'Garment', alias: 'garment' },
  ];
  public activeCategory: IPropertyPanelCategory = this.categories[0];
  public activeGarment: IGarment | undefined = undefined;

  public getCategoryByAlias(alias: string): IPropertyPanelCategory {
    return this.categories.find((category) => category.alias === alias) as IPropertyPanelCategory;
  }
  public title: string = 'Avatar';
  public activeTab: string | null = null;

  constructor(private gltfExportService: GltfExportService) {}

  deselect() {
    this.activeTab = null;
    
  }

  resetValues() {
    this.activeCategory = this.categories[0];
    this.activeTab = null;
    this.activeGarment = undefined;
    this.title = 'Avatar';
  }

  displayGarmentAttributes(garment: IGarment) {
    this.activeCategory = this.getCategoryByAlias('garment');
    this.activeGarment = garment;
    this.title = garment.name;

  }

  activateTab(index: string) {
    this.activeTab = index;
  }

  applyXYCoords(event: any) {
    this.activeValues['curvy-gender'] = new Vector2(event.x, event.y);
  }

  changeRoughness(value: number) {
    this.activeValues['avatar_roughness'] = value;
  }

  changeMetallic(value: number) {
    this.activeValues['avatar_metallic'] = value;
  }

  changeAvatarColor(color: string) {
    this.activeValues['avatar_color'] = color;
  }

  exportGltf() {
    this.gltfExportService.exportScene(this.viewport.scene, 'new_look');
  }

  exportGarmentGltf() {
    if (this.activeGarment) {
      this.gltfExportService.exportMesh(this.activeGarment.mesh!, this.activeGarment.name.toLowerCase().replace(/ /g, '_'));
    }
  }

  setActiveCategory(name: string) {
    this.activeCategory = this.getCategoryByAlias(name);
  }

  show() {
    console.log("Showing property panel");
    this.hidden = false;
  }

  hide() {
    console.log("Hiding property panel");
    this.hidden = true;
  }
}
