<div class="flex flex-col h-full">
    <app-navbar></app-navbar>

    <div class="flex items-center justify-center grow">
        <div class="w-80">
            <h2 class="text-2xl font-bold text-black dark:text-white text-center uppercase">Login</h2>
            <h3 class="text-grey-7000 dark:text-grey-400 text-center">To access the studio app.</h3>
            <div class="space-y-4 mt-8">
                <button (click)="loginGoogle()"
                    class="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105 flex items-center justify-center">
                    <svg class="w-5 h-5 mr-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z"
                            fill="currentColor" />
                    </svg>
                    Log in with Google
                </button>
                <button
                    class="w-full bg-grey-300 dark:bg-grey-900 text-grey-700 dark:text-grey-400 font-semibold py-2 px-4 rounded-md cursor-not-allowed"
                    disabled>
                    Log in with Facebook
                </button>
                <button
                    class="w-full bg-grey-300 dark:bg-grey-900 text-grey-700 dark:text-grey-400 font-semibold py-2 px-4 rounded-md cursor-not-allowed"
                    disabled>
                    Log in with Instagram
                </button>
            </div>
        </div>
    </div>

</div>