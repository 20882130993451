import { Component } from '@angular/core';
import { ThemeSwitchService } from 'src/app/services/theme-switch.service';

@Component({
  selector: 'app-theme-switch',
  templateUrl: './theme-switch.component.html',
})
export class ThemeSwitchComponent {

  constructor(private themeSwitchService: ThemeSwitchService) {
  }

  toggleTheme() {
    this.themeSwitchService.toggleTheme();
  }
}
