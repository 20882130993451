import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrl: './color-picker.component.scss'
})
export class ColorPickerComponent {
  @Input() color: string = '#ffffff';
  @Output() onColorChange = new EventEmitter<string>();

  onColorInputChange(event: Event): void {
    const newColor = (event.target as HTMLInputElement).value;
    this.onColorChange.emit(newColor);
  }
}
