import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Color4, Scene } from '@babylonjs/core';
import { EnvironmentLightingSetupService } from 'src/app/services/babylonjs/viewport/environment-lighting-setup.service';
import { SceneSetupService } from 'src/app/services/babylonjs/viewport/scene-setup.service';
import { ThemeSwitchService } from 'src/app/services/theme-switch.service';

@Component({
  selector: 'app-viewport',
  template: '<canvas class="absolute top-0 left-0 w-full h-full pointer-events-auto touch-none outline-none" #renderCanvas></canvas>',
})
export class ViewportComponent implements AfterViewInit {

  private envFiles: string[] = [
    'syferfontein_0d_clear_puresky_1k',
    'syferfontein_6d_clear_puresky_1k',
   'studio_light' ,
   'studio_dark',
   'rosendal_park_sunset_puresky_1k',
   'kloppenheim_07_puresky_1k',
   'kloppenheim_06_puresky_1k',
   'kloofendal_overcast_puresky_1k',
   'kloofendal_misty_morning_puresky_1k',
   'belfast_sunset_puresky_1k',
   'autumn_field_1k'

  ];

  getEnvBackgrounds(): string[] {
    return this.envFiles;
  }

  loadENV(name: string) {
    this.environmentLightingSetupService.loadENV(this.scene, `assets/img/env/${name}.env`);
  }

  @ViewChild('renderCanvas', { static: true }) renderCanvas!: ElementRef<HTMLCanvasElement>;

  public scene!: Scene;

  constructor(
    private sceneSetupService: SceneSetupService,
    private environmentLightingSetupService: EnvironmentLightingSetupService,
    private themeSwitchService: ThemeSwitchService
  ) { }

  ngAfterViewInit(): void {
    this.scene = this.sceneSetupService.setupScene(this.renderCanvas.nativeElement);

    this.themeSwitchService.themeSubject.subscribe((theme) => {
      if (theme === 'light') {
        this.setupLight();
      } else {
        this.setupDark();
      }
    });
  }

  setupDark() {
    this.loadENV('kloppenheim_07_puresky_1k');
  }

  setupLight() {
    this.loadENV('kloofendal_overcast_puresky_1k');
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowRight') {
      this.changeBackgroundToNextIndex();
    } else if (event.key === 'ArrowLeft') {
      this.changeBackgroundToPreviousIndex();
    }
  }

  private changeBackgroundToPreviousIndex() {
    this.currentEnvIndex = (this.currentEnvIndex - 1 + this.envFiles.length) % this.envFiles.length;
    this.loadENV(this.envFiles[this.currentEnvIndex]);
  }

  private currentEnvIndex: number = 0;

  private changeBackgroundToNextIndex() {
    this.currentEnvIndex = (this.currentEnvIndex + 1) % this.envFiles.length;
    this.loadENV(this.envFiles[this.currentEnvIndex]);
  }



}
