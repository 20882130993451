import { Injectable } from '@angular/core';
import { Color3, Mesh, MeshBuilder, Scene, Vector3, VertexBuffer } from '@babylonjs/core';

@Injectable({
  providedIn: 'root'
})
export class InspectorService {
  private normalLinesRed: Vector3[][] = [];
  private normalLinesGreen: Vector3[][] = [];

  constructor() {

  }

  createNormalLineSystem(scene: Scene) {
    if(this.normalLinesRed.length > 0) {
      const lineSystem = MeshBuilder.CreateLineSystem("normalLines", {lines: this.normalLinesRed}, scene);
      lineSystem.color = Color3.Red();
    }

    if(this.normalLinesGreen.length > 0){
      const lineSystem2 = MeshBuilder.CreateLineSystem("normalLines", {lines: this.normalLinesGreen}, scene);
      lineSystem2.color = Color3.Green();
    }
  }

  showNormals(mesh: Mesh) {
    const normals = mesh.getVerticesData(VertexBuffer.NormalKind)!;
    const positions = mesh.getVerticesData(VertexBuffer.PositionKind)!;

    for (let i = 0; i < normals.length; i += 3) {
      const v1 = Vector3.FromArray(positions, i);
      const v2 = v1.add(Vector3.FromArray(normals, i).scaleInPlace(0.05));
      this.normalLinesRed.push( [v1.add(mesh.position), v2.add(mesh.position)]); }
  }

  addRedNormalLine(mesh: Mesh, positionVector: Vector3, normalVector: Vector3) {
    const scaledNormal = normalVector.scale(0.05);
    const v2 = positionVector.add(scaledNormal);
    this.normalLinesRed.push([
      positionVector.add(mesh.position),
      v2.add(mesh.position)
    ]);
  }

  addGreenNormalLine(mesh: Mesh, positionVector: Vector3, normalVector: Vector3) {
    const scaledNormal = normalVector.scale(0.05);
    const v2 = positionVector.add(scaledNormal);
    this.normalLinesGreen.push([
      positionVector.add(mesh.position),
      v2.add(mesh.position)
    ]);
  }
}
